import React from "react";
import styles from "./styles.module.scss";

const InstallDescription = ({ description, key }) => {
  return (
    <div key={key} className={styles.description}>
      {description}
    </div>
  );
};

export default InstallDescription;
