import React from "react";
import styles from "./styles.module.scss";
import HowTheBehaviourWorks from "../HowTheBehaviourWorks";
import SUDayView from "../SUDayView";

const BehaviourNighttimeDayView = ({
  allMotionEvents,
  movementV3Data,
  howItWorks,
  insights,
  showLearnMore,
  setShowLearnMore,
}) => {
  const daysMovementData = movementV3Data?.[0];

  // Check if there is no data
  const noData =
    daysMovementData?.durationMinutes?.nightTime >= 0 ? false : true;

  return (
    <>
      {showLearnMore && (
        <div className={styles.container}>
          <HowTheBehaviourWorks
            behaviour="Night-time"
            questionsAndAnswers={howItWorks}
            hide={() => setShowLearnMore(false)}
          />
        </div>
      )}
      {!showLearnMore && (
        <SUDayView
          currentTab={"Night-time"}
          events={allMotionEvents}
          noData={noData}
          insightsContext={
            "Night-time activity is the time someone has spent moving around their home during the previous night between 10pm - 6am."
          }
          insights={insights}
          isNewVariant={true}
          isNighttime={true}
          noSplitting={false}
        />
      )}
    </>
  );
};

export default BehaviourNighttimeDayView;
