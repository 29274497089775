import React from "react";
import styles from "./styles.module.scss";
import Donut from "../../Charts/Donut";
import { getHoursAndMinutesAsString } from "../../Services/Utils";

const MovementBreakdown = ({ data, dateType, isCurrentPeriod }) => {
  const { hasNoData, timePeriod, value, label, percentChange, expected } = data;

  const showCircle = !hasNoData;

  const prettyTimePeriod = isCurrentPeriod
    ? `This ${dateType}`
    : `Last ${dateType}`;

  const actualDuration = getHoursAndMinutesAsString(value);
  const expectedDuration = getHoursAndMinutesAsString(expected);

  return (
    <div className={styles.movement_breakdown_container}>
      <div className={styles.top}>
        <div className={styles.last_period}>{prettyTimePeriod}</div>
        <div className={styles.dates}>{timePeriod}</div>
      </div>

      <hr></hr>

      <div className={styles.middle}>
        {hasNoData && (
          <div className={styles.expected_events}>
            There was no data {isCurrentPeriod ? "this" : "last"} {dateType}
          </div>
        )}
        {!hasNoData && (
          <div className={styles.expected_events}>
            We expected&nbsp;
            <span className={styles.underline}>{expectedDuration}</span>
            &nbsp;and we saw:
          </div>
        )}
        <div className={styles.actual_events}>
          {showCircle && (
            <div className={styles.temporary_donut}>
              <Donut
                percentage={
                  expected <= 0
                    ? 100
                    : value <= 0
                      ? 0
                      : Math.round((value / expected) * 100)
                }
                percentChange={1.5}
              />
            </div>
          )}
          {!hasNoData && (
            <div className={styles.stats}>
              <div className={styles.main_stat}>{actualDuration}</div>
              <div className={styles.label}>{label}</div>
              <div
                className={`${
                  isCurrentPeriod ? styles.color : styles.no_color
                }`}
              >
                {percentChange}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovementBreakdown;
