import { useEffect, useRef } from "react";

export const useIsMounted = () => {
  /* 
    The following isMounted ref and the useEffect with cleanup function make sure the async call to publish the report does not produce 
    memory leaks should the component be ummounted before the promise resolves
  */
  const isMounted = useRef(true);
  // Use cases
  // i.e. Showing an alert when an error occurs when attempting to download a report
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};
