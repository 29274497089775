export const sustenanceRangeMissedMeals = (sustenanceData, onClick) => {
  const noMealDays = sustenanceData?.filter(
    (day) => day.sentences && day.sentences.length === 3
  ).length;

  if (!sustenanceData || sustenanceData?.length === 0) {
    return {
      offline: true,
      title: "No meals",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }
  return {
    title: "No meals",
    headline: `${noMealDays} day${noMealDays === 1 ? "" : "s"}`,
    icon: "SUSTENANCE",
    context:
      "Days where all three meals were missed (breakfast, lunch and dinner)",
    onClick: onClick,
  };
};
