export const DOOR_INSTRUCTIONS = [
  {
    text: "1. Open the casing of the sensor by pushing the fastening on the top of the device to remove the front cover from the base.",
  },
  {
    image: {
      image: "/images/installation/sensors/door/door-open.png",
      alt: "Door sensor open",
    },
  },
  {
    text: "2. Insert the enclosed batteries into the device and close the casing.",
  },
  {
    text: "3. Expose the adhesive strip by removing the backing paper of sensor and magnet.",
  },
  {
    text: "4. Stick the magnet to the very edge of the door and the sensor to the door frame. Line up the magnet no more than half a cm apart from the small triangle on one side of the sensor.",
  },
  {
    note: "When the door opens and closes, the sensor and magnet must separate.",
  },
  {
    text: "5. Check whether the green light on the sensor flashes when you open or close the door.",
  },
  {
    image: {
      image: "/images/installation/sensors/door/door-active.png",
      alt: "Door sensor active",
    },
  },
  {
    description:
      "Once you can see the green light you can go back to the installation page and press the 'Test connection' button.",
  },
];
