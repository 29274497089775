import React from "react";
import theme from "../../theme.scss";

export const KebabMenuIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      viewBox="0 0 32 32"
      id="KebabMenuIcon"
    >
      <path
        d="M9 17C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17Z"
        stroke={color || theme.black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17Z"
        stroke={color || theme.black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 17C23.5523 17 24 16.5523 24 16C24 15.4477 23.5523 15 23 15C22.4477 15 22 15.4477 22 16C22 16.5523 22.4477 17 23 17Z"
        stroke={color || theme.black}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
