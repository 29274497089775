import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useChangeLilliUserPage = ({
  serviceUserIds,
  currentServiceUser,
}) => {
  const currentUserIndex = serviceUserIds?.findIndex(
    (su) => su === Number(currentServiceUser)
  );

  const initialState = currentUserIndex >= 0 ? currentUserIndex : 0;
  const [index, setIndex] = useState(initialState);

  const location = useLocation();
  const navigate = useNavigate();

  const goToPrevious = () => {
    const prevIndex = index === 0 ? serviceUserIds.length - 1 : index - 1;
    setIndex(prevIndex);
    navigate(`/lilli-users/${serviceUserIds[prevIndex]}${location.search}`);
  };

  const goToNext = () => {
    const nextIndex = index === serviceUserIds.length - 1 ? 0 : index + 1;
    setIndex(nextIndex);
    navigate(`/lilli-users/${serviceUserIds[nextIndex]}${location.search}`);
  };

  return {
    goToNext,
    goToPrevious,
  };
};
