import React from "react";
import styles from "./styles.module.scss";

export const Avatar = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={`
        ${styles.avatar_container}
        ${
          props.small
            ? styles.avatar_container_small
            : props.large
              ? styles.avatar_container_large
              : ""
        }
        ${props.overlap && styles.overlap}
        ${props.className && styles.className}
        ${props.defaultCursor && styles.default_cursor}
        ${props.lowerOpacity && styles.does_not_match_search_result}
      `}
    >
      <div className={styles.avatar_initials}>
        {props.initials}
        {props.avatarToolTip && (
          <span className={styles.avatar_tooltip}>{props.avatarToolTip}</span>
        )}
      </div>
    </div>
  );
};
