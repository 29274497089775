import { Button } from "@mui/material";
import theme from "../../Styles/theme.scss";
import { styled } from "@mui/material/styles";

const PrimaryButtonStyled = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "hoverColour" &&
    prop !== "backgroundColour" &&
    prop !== "colour" &&
    prop !== "border" &&
    prop !== "padding" &&
    prop !== "iconMargin", // https://stackoverflow.com/questions/70102062/how-to-prevent-passing-props-to-inner-styled-component
})(
  ({
    colour,
    border,
    padding,
    hoverColour,
    backgroundColour,
    iconMargin,
    iconmarginbottom,
  }) => ({
    color: colour || theme.neutral7,
    textTransform: "none",
    border: border || `3.2px solid ${backgroundColour || theme.primary3}`,
    padding: padding || "4px 8px",
    backgroundColor: backgroundColour || theme.primary3,
    "&:hover": {
      border: border || `3.2px solid ${hoverColour || theme.primary4}`,
      backgroundColor: hoverColour || theme.primary4,
      color: colour || theme.neutral7,
    },
    "&:active": {
      border: `3.2px solid ${theme.primary3}`,
      backgroundColor: theme.primary3,
      color: theme.white,
    },
    "&:disabled": {
      color: theme.neutral4,
      backgroundColor: theme.neutral2,
      border: `3.2px solid ${theme.neutral2}`,
    },
    "& .MuiButton-startIcon": {
      marginRight: iconMargin || 6,
      marginBottom: iconmarginbottom || 3,
    },
    "& .MuiButton-endIcon": {
      marginLeft: iconMargin || 6,
      marginBottom: iconmarginbottom || 3,
    },
  })
);

const PrimaryButton = ({
  children,
  style,
  className,
  endIcon,
  onClick,
  startIcon,
  disabled,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
  type,
  colour,
  hoverColour,
  padding,
  backgroundColour,
  border,
  iconMargin,
  iconmarginbottom,
  cypresstestid,
}) => {
  return (
    <PrimaryButtonStyled
      type={type}
      className={className}
      style={style}
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      cypresstestid={cypresstestid}
      colour={colour}
      hoverColour={hoverColour}
      padding={padding}
      backgroundColour={backgroundColour}
      border={border}
      iconMargin={iconMargin}
      iconmarginbottom={iconmarginbottom}
    >
      {children}
    </PrimaryButtonStyled>
  );
};

export default PrimaryButton;
