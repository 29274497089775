import React from "react";
import theme from "../../theme.scss";

export const ArrowCircularRightIcon = ({ size, colour }) => {
  return (
    <svg
      height={size || "48"}
      width={size || "48"}
      viewBox="0 0 48 48"
      id="ArrowCircularRightIcon"
    >
      <path
        d="M24 32L32 24M32 24L24 16M32 24H16M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24Z"
        stroke={colour || theme.neutral7}
        stroke-width="4"
        fill={"white"}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
