import { useState } from "react";

export const useDownloadReport = ({
  smallScreen,
  error,
  downloadingReport,
}) => {
  const [reportURL, setReportURL] = useState(null);

  const getButtonText = () => {
    if (error) {
      if (smallScreen) {
        return "Failed";
      }
      return "Failed to process";
    }
    switch (downloadingReport) {
      case true:
        return "Downloading...";
      case false:
        if (smallScreen) {
          return "Download";
        }
        return "Download Report";
      default:
        if (smallScreen) {
          return "Generate";
        }
        return "Generate Report";
    }
  };

  return {
    reportURL,
    setReportURL,
    getButtonText,
  };
};
