import React from "react";
import { PropTypes } from "prop-types";
// Components
import ServiceUserImage from "../ServiceUserImage";
import PieChart from "../../Charts/PieChart";
// Styles
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Utils
import { getRiskColour, RiskStatus } from "@intelligentlilli/lilli-utils";
import { RiskLevelIcon } from "../../Services/Utils/Constants/risks";

const ServiceUserDoughnut = (props) => {
  // Getting the correct icon for the behaviour level
  const BehaviourLevelIconTag =
    RiskLevelIcon[
      props.riskStatus === 2
        ? RiskStatus.AT_RISK
        : props.riskStatus === 1
          ? RiskStatus.UNEXPECTED
          : RiskStatus.EXPECTED
    ];
  return (
    <div className={styles.doughnut}>
      <PieChart
        className={styles.chart}
        innerRadius={25}
        radius={40}
        atRisk={props.weekRisks?.atRisk}
        unexpected={props.weekRisks?.unexpected}
        expected={props.weekRisks?.expected}
      />
      <ServiceUserImage
        className={styles.image}
        initials={props.initials}
        profilePhotoURL={props.profileImage}
        altText={props.altText}
        small
      />
      {!isNaN(props.riskStatus) && (
        <div className={styles.icon}>
          <BehaviourLevelIconTag
            style={{
              color: getRiskColour(
                props.riskStatus === 2
                  ? RiskStatus.AT_RISK
                  : props.riskStatus === 1
                    ? RiskStatus.UNEXPECTED
                    : RiskStatus.EXPECTED,
                {
                  red: theme.risk3,
                  orange: theme.unexpected3,
                  green: theme.expected2,
                }
              ),
              width: 18,
              height: 18,
            }}
          />
        </div>
      )}
    </div>
  );
};

ServiceUserDoughnut.propTypes = {
  initials: PropTypes.string,
  profileImage: PropTypes.string,
  weekRisks: PropTypes.object,
};

export default ServiceUserDoughnut;
