import styles from "./styles.module.scss";
import Square from "../Square";

const SensorSquares = ({ selectedSensor, sensors, onClick }) => {
  return (
    <div className={styles.sensor_squares_container}>
      <div className={styles.sensor_squares}>
        {sensors?.map((sensor, index) => (
          <div styles={styles.square} key={index}>
            <Square
              selectedSensor={selectedSensor}
              sensor={sensor}
              onClick={onClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SensorSquares;
