import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postCreateReportV2,
  postCreateReportV1,
  getReportStatusV2,
  deleteReportV1,
  deleteReportV2,
  getReportsV2,
  postUpdateReport,
  getReportPreview,
  getReportsV1,
  getReportTypesV2,
} from "@intelligentlilli/api-layer";
// State
import { setLoading } from "../../State/slices/session-slice";
import { setReports } from "../../State/slices/reports-slice";
import { setReportTypes } from "../../State/slices/reportTypes-slice";
import { addDays, differenceInDays, parseISO } from "date-fns";
import { getArrayOfReportOptions } from "../Utils";

export const useReportsHook = (
  server,
  navigate,
  setLocalLoading,
  setLocalError
) => {
  const dispatch = useDispatch();
  const reportTypes = useSelector((state) => state.reportTypes);
  const reports = useSelector((state) => state.reports);
  const [closeConfirmModal, setCloseConfirmModal] = useState(true);
  const [isSavingChanges, setIsSavingChanges] = useState(null);

  useEffect(() => {
    setLocalError && setLocalError(false);
    setLocalLoading && setLocalLoading(true);
    async function fetchData() {
      const reportTypesResponse = getReportTypesV2(server, "web");
      const reportsResponse = getReportsV2(server, "web");
      const reportsV1Response = getReportsV1(server, "web");

      await Promise.all([
        reportTypesResponse,
        reportsResponse,
        reportsV1Response,
      ])
        .then((res) => {
          if (res[0]?.ok && res[1]?.ok && res[2]?.ok) {
            // Convert the V1 reports response into the V2 format
            const reportsV1 = res[2].body.map((report) => {
              return {
                ...report,
                created_at: report.ts,
                end_at: addDays(
                  new Date(report.reportstartdate),
                  report.days
                ).toISOString(),
                entity_id: `${report.serviceuserids[0]}`,
                report_type: report.typeid,
                start_at: new Date(report.reportstartdate).toISOString(),
              };
            });
            dispatch(setReportTypes(res[0]?.body));
            dispatch(setReports([...res[1]?.body, ...reportsV1]));
          } else {
            setLocalError && setLocalError(true);
          }
          setLocalLoading && setLocalLoading(false);
        })
        .catch((err) => {
          if (err.status === 401) {
            navigate("/login");
          }
          setLocalLoading && setLocalLoading(false);
          setLocalError && setLocalError(true);
        });
    }
    fetchData();
  }, [navigate, server, dispatch, setLocalLoading, setLocalError]);

  const createReport = (
    {
      serviceUserId,
      reportType,
      reportStartDate,
      reportEndDate,
      entityProperties,
    },
    { reportName, options },
    setLocalLoading,
    setLocalError
  ) => {
    if (reportType === "general-report") {
      setLocalLoading(true);
      postCreateReportV1(
        server,
        reportType, // "general-report"
        reportName, // name
        differenceInDays(parseISO(reportEndDate), parseISO(reportStartDate)) +
          1, // days
        reportStartDate, // "YYYY-MM-DD"
        serviceUserId, // service user ID
        options, // options ["sustenance", ... ]
        null,
        "web"
      )
        .then((res) => {
          setLocalLoading(false);
          if (!res.ok) {
            setLocalError(true);
          } else {
            navigate(`/pdf-preview/${res.body.id}`);
          }
        })
        .catch(() => {
          setLocalLoading(false);
          setLocalError(true);
        });
    } else {
      setLocalLoading(true);
      postCreateReportV2(
        server,
        serviceUserId, // "general-report"
        reportType, // name
        reportStartDate,
        reportEndDate, // "YYYY-MM-DD"
        entityProperties,
        "web"
      )
        .then((res) => {
          if (res.ok) {
            // get the report status
            const reportId = res.body.requestID;
            // poll the get reports endpoint until the report is ready
            // Number of times to call the report status endpoint
            let iterations = 40;
            // Set an interval to fetch the status
            const interval = window.setInterval(() => {
              if (iterations > 0) {
                getReportStatusV2(server, reportId, "web").then((res) => {
                  if (res.ok) {
                    // If the response comes back with a location we can stop looping and download the report
                    if (res.body.current_stage === "ready") {
                      setLocalLoading(false);
                      window.clearInterval(interval);
                      navigate(`/pdf-previewV2/${res.body.request_id}`);
                    }
                    // Otherwise the report is still in the queue so iterate again and reduce the number by 1
                    else {
                      iterations--;
                    }
                    // Once we get to zero iterations clear the interval stop the spinner and show the error message
                    if (iterations === 0) {
                      window.clearInterval(interval);
                      setLocalLoading(false);
                      setLocalError(true);
                    }
                  } else {
                    window.clearInterval(interval);
                  }
                });
              }
            }, 2000);
          } else {
            setLocalLoading(false);
            setLocalError(true);
          }
        })
        .catch(() => {
          setLocalLoading(false);
          setLocalError(true);
        });
    }
  };

  const deleteSelectedReport = (report) => {
    dispatch(setLoading(true));
    if (report.report_type === "general-report") {
      deleteReportV1(server, report.id, "web")
        .then((res) => {
          if (res.ok) {
            // remove the report from the redux store
            dispatch(setReports(reports.filter((r) => r.id !== report.id)));
          }
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    } else {
      deleteReportV2(server, report.request_id, "web")
        .then((res) => {
          if (res.ok) {
            // remove the report from the redux store
            dispatch(
              setReports(
                reports.filter((r) => r.request_id !== report.request_id)
              )
            );
          }
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
    setCloseConfirmModal(true);
  };

  const editAndPreviewReport = (
    formData,
    server,
    reportEndDate,
    setError,
    setData
  ) => {
    setIsSavingChanges(true);
    // Post updates to the report
    postUpdateReport(
      server,
      formData.reportID, // service user ID
      formData.reportType, // i.e. 'general-report',
      formData.reportName, // name person has given the report,
      differenceInDays(
        parseISO(reportEndDate),
        parseISO(formData.reportStartDate)
      ) + 1, // report end date
      formData.reportStartDate, // report start date "YYYY-MM-DD"
      formData.suID, // service user ID
      getArrayOfReportOptions(formData.options), // ["sustenance", ...
      formData.summary,
      "web"
    )
      .then(() => {
        const urlPath = window.location.pathname;
        if (urlPath.includes("pdf-preview")) {
          // Preview the newly edited report
          getReportPreview(server, formData.reportID, "web")
            .then((res) => {
              if (res.ok) {
                setData(res.body);
                setIsSavingChanges(false);
              } else {
                setError(true);
              }
            })
            .catch((err) => {
              setError(true);
            });
        }
      })
      .catch((e) => {
        console.log("error updating report:", e);
        setIsSavingChanges(false);
      });
  };

  return {
    createReport,
    deleteSelectedReport,
    reportTypes,
    reports,
    closeConfirmModal,
    setCloseConfirmModal,
    editAndPreviewReport,
    isSavingChanges,
  };
};
