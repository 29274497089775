export const INITAL_SENSORS = {
  hubId: null,
  hub: null,
  devices: [
    {
      defaultName: "SquidZigBee",
      name: "SquidZigBee",
      displayName: "Lilli hub",
      image: "/images/installation/hub/hub_with_power_landscape.png",
      alt: "Lilli hub with power cord attached to the hub",
      video: "/images/installation/videos/hub.webm",
      online: false,
      eui: "0015BC002C11256E",
      label: "h1",
      id: 0,
    },
    {
      defaultName: "Smart Plug Mini UK",
      name: "Kitchen/Appliance/Power",
      displayName: "Smart plug",
      online: false,
      label: "p1",
      image: "/images/installation/smart_plug/plug.png",
      video: "/images/installation/videos/plug.webm",
      id: 6,
      locationRequired: true,
      locationOptions: [
        { value: "Kettle", label: "Kettle" },
        { value: "Microwave", label: "Microwave" },
        { value: "Toaster", label: "Toaster" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      defaultName: "Motion Sensor Mini",
      name: "Hall/Wall/Motion",
      displayName: "Motion sensor",
      image:
        "/images/installation/motion/motion_sensor_battery_high_contrast.png",
      video: "/images/installation/videos/motion.webm",
      online: false,
      label: "m1",
      locationRequired: true,
      locationOptions: [
        { value: "Hallway", label: "Hallway" },
        { value: "Stairs", label: "Stairs" },
        { value: "Living Room", label: "Living Room" },
        { value: "Kitchen", label: "Kitchen" },
        { value: "Other", label: "Other" },
      ],
      id: 1,
    },
    {
      defaultName: "Motion Sensor Mini",
      name: "Bathroom/Wall/Motion",
      displayName: "Bathroom sensor",
      online: false,
      image:
        "/images/installation/motion/motion_sensor_battery_high_contrast.png",
      video: "/images/installation/videos/motion.webm",
      locationRequired: true,
      locationOptions: [
        { value: "Bath/Shower and Toilet", label: "Bath/Shower and Toilet" },
        { value: "Bath/Shower only", label: "Bath/Shower only" },
        { value: "Toilet only", label: "Toilet only" },
      ],
      label: "m2",
      id: 2,
    },
    {
      defaultName: "Motion Sensor Mini",
      name: "Bedroom/Wall/Motion",
      displayName: "Bedroom sensor",
      online: false,
      image:
        "/images/installation/motion/motion_sensor_battery_high_contrast.png",
      video: "/images/installation/videos/motion.webm",
      label: "m3",
      id: 3,
      locationRequired: false,
    },
    {
      defaultName: "Window Sensor",
      name: "Hall/Main/Door",
      displayName: "Main door sensor",
      online: false,
      label: "d1",
      video: "/images/installation/videos/door.webm",
      id: 4,
      locationRequired: true,
      locationOptions: [
        { value: "Front door", label: "Front door" },
        { value: "Back door", label: "Back door" },
      ],
    },
    {
      defaultName: "Window Sensor",
      name: "Kitchen/Fridge/Door",
      displayName: "Fridge sensor",
      video: "/images/installation/videos/door.webm",
      online: false,
      label: "d2",
      locationRequired: true,
      locationOptions: [
        { value: "Fridge", label: "Fridge" },
        { value: "Cupboard", label: "Cupboard" },
        { value: "Other", label: "Other" },
      ],
      id: 5,
    },
  ],
};
