import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import classNames from "classnames";
import { CloseIcon } from "../../Styles/Icons";

const MobileDrawer = ({ children, showGuide, closeModal }) => {
  return (
    <div
      className={classNames(
        styles.mobileDrawer,
        showGuide === false ? styles.closed : styles.open
      )}
    >
      <div className={styles.close_guide}>
        <CloseIcon
          color={theme.neutral4}
          onClick={() => closeModal(false)}
          className={styles.close_guide_icon}
        />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default MobileDrawer;
