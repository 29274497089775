import React from "react";
import Select from "react-select"; // https://react-select.com/home#getting-started
import { sortByObjectProperty } from "@intelligentlilli/lilli-utils";
import theme from "../../../Styles/theme.scss";
import styles from "./styles.module.scss";

const Step1 = ({
  serviceUser,
  setServiceUser,
  showValidationError,
  serviceUsers,
}) => {
  // -- DATA MANIPULATIONS
  // Note: only shows the service users who have devices installed.
  const suPostInstallation = serviceUsers
    ?.filter(
      (user) => user?.serviceUserProperties?.installationStatus === "complete"
    )
    .reduce((acc, item) => {
      var newObj = {
        name: item?.userstring,
        value: item?.id,
        label: item?.userstring,
      }; // "value & label" are required properties for the 'react-select' library
      return acc.concat(newObj);
    }, []);
  sortByObjectProperty(suPostInstallation, "userstring"); // sort service users alphabeticallys

  return (
    <div className={styles.timeframe}>
      <h2>Which Lilli user would you like a report for?</h2>
      <Select
        data-private
        placeholder="Select a Lilli user"
        options={suPostInstallation}
        name="serviceUsers"
        value={serviceUser}
        onChange={(value) => {
          setServiceUser(value);
        }}
        menuPlacement={"bottom"}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor:
              showValidationError && !serviceUser
                ? theme.atrisk2
                : theme.neutral3,
            padding: "2px 0",
            boxShadow: "none",
            borderRadius: 8,
            color: `${theme.grey4}`,
            marginRight: 8,
            "&:hover": {
              borderColor: theme.neutral4,
            },
          }),
          option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? theme.neutral2 : null,
            color: theme.neutral7,
            // adjust background colour when mouse button is pressed
            ":active": {
              backgroundColor: theme.neutral3,
            },
          }),
        }}
        components={{
          IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
        }}
      />
      {showValidationError && !serviceUser && (
        <p className={styles.required}>Required</p>
      )}
    </div>
  );
};

export default Step1;
