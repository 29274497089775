export const DUMMY_SENSOR_DATA_1 = [
  {
    name: "Hallway sensor",
    key: "hallway",
    value: 10,
    label: "10 min",
  },
  {
    name: "Bedroom sensor",
    key: "bedroom",
    value: 20,
    label: "20 min",
  },
  {
    name: "Smart plug",
    key: "smartplug",
    value: 20,
    label: "20 min",
  },
  {
    name: "Bathroom sensor",
    key: "bathroom",
    value: 20,
    label: "20 min",
  },
];

export const DUMMY_SENSOR_DATA_2 = [
  {
    name: "Hallway sensor",
    key: "hallway",
    value: 30,
    label: "30 min",
  },
  {
    name: "Bedroom sensor",
    key: "bedroom",
    value: 10,
    label: "10 min",
  },
  {
    name: "Smart plug",
    key: "smartplug",
    value: 50,
    label: "50 min",
  },
  {
    name: "Bathroom sensor",
    key: "bathroom",
    value: 10,
    label: "10 min",
  },
];
