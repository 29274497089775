import React, { useState } from "react";
// State
import { useSelector } from "react-redux";
// Router
import { useParams } from "react-router-dom";
// Styles
import styles from "./styles.module.scss";
// Components
import Event from "../Event";
import BehaviourRange from "../BehaviourRange";
import { useViewport } from "../../Components/ViewportProvider";
// Utils
import { format, addHours, startOfDay } from "date-fns";
import { formatMealTime } from "../../Services/Utils";
import { sustenanceRangeMissedMeals } from "../../Services/Utils/Insights";
// icons
import {
  BreakfastWindowIcon,
  LunchWindowIcon,
  DinnerWindowIcon,
} from "../../Styles/Icons/DesignSystem";

// Day View Components
const Divider = () => <div className={styles.divider}></div>;

const EatingAndDrinkingWindow = ({
  eatingAndDrinkingEvents,
  timePeriod,
  timePeriodIcon,
  isDesktop,
}) => {
  const hasEvents = eatingAndDrinkingEvents?.length > 0;
  return (
    <div className={styles.eating_and_drinking_window_container}>
      <div className={styles.time_period}>
        {timePeriodIcon} {timePeriod}
      </div>
      {!isDesktop && <Divider />}
      <div>
        {hasEvents &&
          eatingAndDrinkingEvents?.map((item, index) => {
            return (
              <Event
                event={item?.event}
                frequency={item?.frequency}
                timePeriod={item?.timePeriod}
                key={index}
              />
            );
          })}
        {!hasEvents && <div className={styles.no_events}>No events</div>}
      </div>
    </div>
  );
};

// Component that uses the above sub-components
const BehaviourSustenance = ({
  sustenanceData,
  dateType,
  from,
  to,
  title,
  activeTab,
  changePage,
}) => {
  const { id } = useParams(); // Get the hub id from the url parameter"
  const allSUsData = useSelector((state) => state.serviceUsersData);
  const hubData = allSUsData?.[id];

  const filtersToRender = [
    {
      key: "sustenance",
      value: "Missed one or more meals",
      relatedBehaviour: "sustenance",
    },
    {
      key: "missedAllMeals",
      value: "Missed all three meals",
      relatedBehaviour: "sustenance",
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState(filtersToRender[0]);

  const temporaryMealTimeWindows = {
    breakfast: [5, 10],
    lunch: [11, 16],
    dinner: [17, 21],
  };

  // meals
  const hubMealTimes =
    hubData?.sustenanceAnalysis?.["mealtimeWindows"] ||
    temporaryMealTimeWindows;
  const breakfastStart = hubMealTimes?.["breakfast"]?.[0];
  const breakfastEnd = hubMealTimes?.["breakfast"]?.[1];
  const lunchStart = hubMealTimes?.["lunch"]?.[0];
  const lunchEnd = hubMealTimes?.["lunch"]?.[1];
  const dinnerStart = hubMealTimes?.["dinner"]?.[0];
  const dinnerEnd = hubMealTimes?.["dinner"]?.[1];
  const breakfastTime = `${formatMealTime(breakfastStart)}-${formatMealTime(
    breakfastEnd
  )}`;
  const lunchTime = `${formatMealTime(lunchStart)}-${formatMealTime(lunchEnd)}`;
  const dinnerTime = `${formatMealTime(dinnerStart)}-${formatMealTime(
    dinnerEnd
  )}`;

  // sizing for day view
  const { width } = useViewport();
  const isBiggerThan900pixels = width >= 900;
  // sizing for range view
  const rangeViewIsOnMobileView = width < 900;

  // Data for a single day
  const todaysHourlyData = sustenanceData?.[0]?.hourlyData || [];
  // ^^ note regarding hourly data
  // v1 sustenance = {hour: 0, fridgeCount: 0, kettleCount: 0, averagePower: 0} or {hour: 0, fridgeCount: 0 }
  // v2 sustenance =  {hour: 0, fridgeCount: 1, smartplugCount: null, totalCount: 0 },

  // Format data for a single day
  const formattedHourlyData = todaysHourlyData.map((i, index) => {
    const startOfToday = startOfDay(new Date(to));
    const startHour = format(addHours(startOfToday, index), "h");
    const endHour = format(addHours(startOfToday, index + 1), "h");
    const timePeriod =
      i?.hour === 11
        ? `${startHour}am-${endHour}pm`
        : i?.hour === 23
          ? `${startHour}pm-${endHour}am`
          : i?.hour < 12
            ? `${startHour}-${endHour}am`
            : `${startHour}-${endHour}pm`;
    const events = [];
    for (const [key, value] of Object.entries(i)) {
      if (key !== "totalCount" && key.includes("Count")) {
        events.push({
          event: key.split("Count")?.[0], // kettle, cupboard, fridge, etc.
          frequency: value,
          timePeriod: timePeriod,
          hour: i?.hour,
        });
      }
    }
    return {
      ...i,
      timePeriod: timePeriod,
      events: events,
    };
  });

  // Meals
  // 1- bring all the events into a flattened array
  const flattenedData = formattedHourlyData?.map((hour) => hour?.events).flat();
  // 2- remove events with frequency of 0; we won't show those
  const dataWithEvents = flattenedData.filter((event) => event.frequency > 0);
  // 3- chunk up the mealtimes
  const breakfastData =
    dataWithEvents?.filter(
      (i) => i?.hour >= breakfastStart && i?.hour <= breakfastEnd
    ) || [];
  const lunchData =
    dataWithEvents?.filter(
      (i) => i?.hour >= lunchStart && i?.hour <= lunchEnd
    ) || [];
  const dinnerData =
    dataWithEvents?.filter(
      (i) => i?.hour >= dinnerStart && i?.hour <= dinnerEnd
    ) || [];
  // 4- combine all the meals into one array
  const expectedMeals = breakfastData.concat(lunchData).concat(dinnerData);
  // 5- get the remaining meals
  const lateEarlyMeals = dataWithEvents?.filter(
    (i) => !expectedMeals?.includes(i)
  );

  // Generate the required insights for range view
  const insight1 = sustenanceRangeMissedMeals(sustenanceData, null);

  return dateType === "day" ? (
    <div className={styles.behaviour}>
      <div className={styles.behaviour_top}>
        <div className={styles.behaviour_context}>
          <div className={styles.behaviour_header}>
            {/* Only show the date / date range on the same line as the Title if its on day view or if its bigger than 400px */}
            <h1 className={styles.behaviour_title}>{title}</h1>
          </div>
        </div>
      </div>

      {/* Show a list of eating & drinking events categorised by breakfast, lunch, and dinner */}
      <div className={styles.behaviour_bottom_day_view}>
        <EatingAndDrinkingWindow
          eatingAndDrinkingEvents={breakfastData}
          timePeriod={`Breakfast (${breakfastTime})`}
          timePeriodIcon={<BreakfastWindowIcon />}
          isDesktop={isBiggerThan900pixels}
        />
        {isBiggerThan900pixels && <Divider />}
        <EatingAndDrinkingWindow
          eatingAndDrinkingEvents={lunchData}
          timePeriod={`Lunch (${lunchTime})`}
          timePeriodIcon={<LunchWindowIcon />}
          isDesktop={isBiggerThan900pixels}
        />
        {isBiggerThan900pixels && <Divider />}
        <EatingAndDrinkingWindow
          eatingAndDrinkingEvents={dinnerData}
          timePeriod={`Dinner (${dinnerTime})`}
          timePeriodIcon={<DinnerWindowIcon />}
          isDesktop={isBiggerThan900pixels}
        />
        {isBiggerThan900pixels && <Divider />}
        <EatingAndDrinkingWindow
          eatingAndDrinkingEvents={lateEarlyMeals}
          timePeriod={"Events outside meal windows"}
          isDesktop={isBiggerThan900pixels}
        />
      </div>
    </div>
  ) : (
    <BehaviourRange
      data={sustenanceData}
      from={from}
      to={to}
      activeTab={activeTab}
      filtersToRender={filtersToRender}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      changePage={changePage}
      isMobile={rangeViewIsOnMobileView}
      dateType={dateType}
      insights={[insight1]}
    />
  );
};

export default BehaviourSustenance;
