import React from "react";
import styles from "./styles.module.scss";
// Components
import Page from "../../Components/Page";
import PageTitle from "../../Components/PageTitle";

const Notifications = () => {
  return (
    <Page className={styles.page}>
      <PageTitle title="Notification Centre" />
      <p className={styles.explanation}>
        Here you can view all your notifications. Notifications include: Any
        kind of high risk behaviour from any users and reports becoming ready to
        view.
      </p>
      <p className={styles.courier_notification}>
        Courier sends notifications on behalf of Lilli. See{" "}
        <a
          href="https://www.courier.com/privacy/"
          className={styles.courier_link}
        >
          Courier's Privacy Policy
        </a>{" "}
        for more information.
      </p>
    </Page>
  );
};

export default Notifications;
