import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Icons
import { ErrorIcon, ThumbsUpIcon } from "../../Styles/Icons/DesignSystem";

const ResetPasswordMessage = ({ message, isResetPasswordSuccess }) => {
  const generic = "We were not able to reset the password.";
  const tryAgain = " Please try again or contact support@intelligentlilli.com.";
  const errorMessage = `${message ? message : generic}${tryAgain}`;

  return (
    <div
      className={
        isResetPasswordSuccess
          ? styles.pw_reset_success_container
          : styles.pw_reset_fail_container
      }
    >
      <div className={styles.inner_container}>
        <div className={styles.top_container}>
          <div className={styles.icon}>
            {isResetPasswordSuccess ? (
              <ThumbsUpIcon color={theme.expected2} width={20} />
            ) : (
              <ErrorIcon color={theme.risk4} size={24} />
            )}
          </div>
          <div className={styles.text}>
            <div className={styles.title}>
              {isResetPasswordSuccess
                ? "Password set"
                : "Unable to reset password"}
            </div>
          </div>
        </div>
        <div className={styles.bottom_container}>
          <div className={styles.reset_pw_message}>
            {isResetPasswordSuccess
              ? "You have successfully set your password. Go back to login and enter your credentials."
              : errorMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordMessage;
