import React from "react";
import styles from "./styles.module.scss";
// form library
import { Form, Field } from "react-final-form";
// components
import CTAButton from "../../Components/CTAButton";
import Checkbox from "../../Components/Checkbox";
import FormError from "../../Components/FormError";
import LilliSelect from "../../Components/LilliDesignSystem/LilliSelect";
// icons
import { CalendarIcon } from "../../Styles/Icons/DesignSystem";
import { CloseIcon } from "../../Styles/Icons";
// utils
import { isAfter } from "date-fns";
import { reverseDate, sortHigh } from "@intelligentlilli/lilli-utils";
import { isValidName, cleanPostcode, isHTML } from "../../Services/Utils";
import BehaviourSelection from "./components/BehaviourSelection";

const AddServiceUserForm = ({
  error,
  onSubmit,
  staff,
  userId,
  justAnInstaller,
}) => {
  // First we manipulate the staff array, adding the object keys required for the select component and excluding the current user

  const staffToSelect = sortHigh(
    staff
      ?.map((staffMember) => ({
        ...staffMember,
        value: `${staffMember?.forenames} ${staffMember?.surname}`,
        label: `${staffMember?.forenames} ${staffMember?.surname}`,
      }))
      ?.filter((staffMember) => staffMember.id !== userId),
    "label"
  );

  return (
    <Form
      onSubmit={(values) =>
        onSubmit(
          values.forename,
          values.surname,
          cleanPostcode(values.postcode),
          values.houseNumber,
          reverseDate(values.dateOfBirth),
          values.careId,
          values.conditions,
          values.careType,
          values.carerAssignment,
          values.assignToMe,
          values.monitoring,
          values.firstBehaviour,
          values.secondBehavour,
          values.dayNightTimeBehaviour
        )
      }
      validate={(values) => {
        const errors = {};
        if (!values.forename) {
          errors.forename = "Required";
        }
        if (values.forename && !isValidName(values.forename)) {
          errors.forename = "Invalid forename";
        }
        if (!values.surname) {
          errors.surname = "Required";
        }
        if (values.surname && !isValidName(values.surname)) {
          errors.surname = "Invalid surname";
        }
        if (!values.postcode) {
          errors.postcode = "Required";
        }
        if (!values.houseNumber) {
          errors.houseNumber = "Required";
        }

        // Only validate dateOfBirth if it is provided
        if (values.dateOfBirth) {
          if (isAfter(new Date(values.dateOfBirth), new Date())) {
            errors.dateOfBirth = "Incompatible date";
          }
          if (values.dateOfBirth.length !== 10) {
            errors.dateOfBirth = "Incompatible date";
          }
          // Regex to ensure the date is in DD-MM-YYYY format
          const dateRegex =
            /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-(19|20)\d\d$/;
          const isValidDOB = dateRegex.test(values.dateOfBirth);

          if (!isValidDOB) {
            errors.dateOfBirth = "Invalid date: use DD-MM-YYYY";
          }
        }
        if (values.monitoring?.length > 600) {
          errors.monitoring = "You've hit the 600 character limit.";
        }
        if (isHTML(values.monitoring)) {
          errors.monitoring = "HTML is not allowed.";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, valid, form }) => (
        <form
          className={styles.form}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
        >
          <div className={styles.form_fields}>
            <Field name="forename">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>
                    First name of Lilli user<sup>*</sup>
                  </div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                    placeholder="John"
                  />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="surname">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>
                    Last name of Lilli user<sup>*</sup>
                  </div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                    placeholder="Smith"
                  />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="houseNumber">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>
                    House number / name<sup>*</sup>
                  </div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                  />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="postcode">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>
                    Postcode<sup>*</sup>
                  </div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                  />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="dateOfBirth">
              {({ input, meta }) => {
                return (
                  <div className={styles.field}>
                    <div className={styles.label}>Date of birth</div>
                    <div className={styles.field_dob}>
                      <CalendarIcon />
                      <input
                        data-private
                        className={styles.input_dob}
                        {...input}
                        type="text"
                        placeholder="DD-MM-YYYY"
                        maxLength="10"
                        onChange={(event) => {
                          const newValue = event.target.value;
                          if (
                            (newValue.length === 2 || newValue.length === 5) &&
                            input.value[input.value.length - 1] !== "-"
                          ) {
                            form.change(
                              "dateOfBirth",
                              `${event.target.value}-`
                            );
                          } else if (
                            (newValue.length === 2 || newValue.length === 5) &&
                            input.value[input.value.length - 1] === "-"
                          ) {
                            form.change(
                              "dateOfBirth",
                              `${event.target.value.slice(0, -1)}`
                            );
                          } else {
                            form.change("dateOfBirth", event.target.value); // Use form.change instead of change
                          }
                        }}
                      />
                    </div>

                    {meta.error && meta.touched ? (
                      <span className={styles.error}>{meta.error}</span>
                    ) : null}
                  </div>
                );
              }}
            </Field>
            <Field name="careId">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>Care ID</div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                  />
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>

            <Field name="conditions">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>Conditions</div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                    placeholder="e.g. Dementia, High blood pressure"
                  />
                  <span className={styles.tip}>
                    Separate conditions with commas
                  </span>
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="careType">
              {({ input, meta }) => (
                <div className={styles.field}>
                  <div className={styles.label}>Care type</div>
                  <input
                    data-private
                    className={styles.input}
                    {...input}
                    type="text"
                    placeholder="e.g. Home care, Long term care"
                  />
                  <span className={styles.tip}>
                    Separate care types with commas
                  </span>
                  {meta.error && meta.touched && (
                    <span className={styles.error}>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
            <Field name="carerAssignment">
              {({ input }) => {
                // Only show staff who have not been assigned in the list
                const staffNotYetSelected = staffToSelect.filter(
                  (staffMember) => !input.value.includes(staffMember)
                );
                return (
                  <>
                    <div className={styles.field}>
                      <div className={styles.label}>Carer assignment</div>
                      <LilliSelect
                        dataPrivate
                        withinModal
                        placeholder="Select carer name"
                        options={staffNotYetSelected}
                        name="serviceUsers"
                        onChange={(change) => {
                          // Take the new staff member and add them to the array of staff members assigned
                          let newStaff = [];
                          if (Array.isArray(input.value)) {
                            newStaff = [...input.value];
                          }
                          newStaff.push(change);
                          form.change("carerAssignment", newStaff);
                        }}
                        value={[]}
                        isClearable
                        isSearchable
                        menuPlacement="bottom"
                      />
                      {/* render the list of staff members assigned */}
                      <div className={styles.users}>
                        {input?.value?.length > 0 &&
                          input?.value?.map((user, index) => {
                            return (
                              <div className={styles.user} key={index}>
                                <CloseIcon
                                  onClick={() => {
                                    const newStaff = [...input.value];
                                    newStaff.splice(index, 1);
                                    form.change("carerAssignment", newStaff);
                                  }}
                                  className={styles.deleteStaff}
                                />
                                <span>{user?.value}</span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <Field
                      name="assignToMe"
                      type="checkbox"
                      initialValue={justAnInstaller}
                    >
                      {({ input }) => (
                        <div className={styles.field_check}>
                          <Checkbox input={input} />
                          <span>Assign this user to me</span>
                        </div>
                      )}
                    </Field>
                  </>
                );
              }}
            </Field>
          </div>
          {error && <FormError error={error} />}
          <div className={styles.monitoring_text_area}>
            <Field name="monitoring" type="freeText" initialValue={""}>
              {({ input, meta }) => {
                return (
                  <div className={styles.field}>
                    <div className={styles.label}>
                      What do you want to find out by monitoring this person
                    </div>
                    <textarea
                      data-private
                      className={styles.text_area}
                      name="monitoring"
                      placeholder="For example... Mary has dementia so I want to know about door opening and time outside at night."
                      maxLength={601}
                      {...input}
                    />
                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                );
              }}
            </Field>
            <BehaviourSelection />
          </div>
          <CTAButton
            disabled={submitting || pristine || !valid}
            type="submit"
            className={styles.submit}
            cypresstestid="Create-New-Service-User-Btn-Submit"
          >
            Create
          </CTAButton>
        </form>
      )}
    />
  );
};

export default AddServiceUserForm;
