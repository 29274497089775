import React from "react";
import styles from "./styles.module.scss";

const SensorGuideHeader = ({ instructions, isDesktop }) => {
  return (
    <div>
      {/* leave padding-top as 10px on mobile so that the image can be displayed properly
        this is necessary for mobile, to scroll to the top of the container 
        without cropping the top of the image 
      */}
      {instructions?.image && (
        <div
          style={{
            display: "flex",
            justifyContent: `${isDesktop ? "flex-start" : "center"}`,
            paddingTop: !isDesktop ? "10px" : "0px",
          }}
        >
          <img
            src={instructions?.image}
            alt={instructions?.alt}
            style={{
              maxWidth: `${instructions?.width}px`,
              maxHeight: `${instructions?.height}px` || "auto",
            }}
            className={styles.header_image}
          />
        </div>
      )}

      <div className={styles.title}>{instructions?.title}</div>
    </div>
  );
};

export default SensorGuideHeader;
