import React from "react";
import styles from "./styles.module.scss";

const Stepper = ({ steps, currentStepIndex }) => {
  const numberOfSteps = steps.length;
  // Calculate the width of the line
  // depending on the number of steps
  const lineWidth = 100 / (numberOfSteps - 1);

  return (
    <div className={styles.stepper}>
      <progress
        value={currentStepIndex * lineWidth}
        max="100"
        className={styles.progress}
      />
      {steps.map((step, index) => (
        <div className={styles.stepper_step}>
          <div
            className={
              index > currentStepIndex
                ? styles.stepper_step_blob
                : styles.stepper_step_blob_complete
            }
          >
            {step.icon}
          </div>
          <div className={styles.stepper_step_label} data-private>
            {step.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
