import React from "react";
import styles from "./styles.module.scss";

const InstallNote = ({ note, key }) => {
  return (
    <div className={styles.note} key={key}>
      Note: <span>{note}</span>
    </div>
  );
};

export default InstallNote;
