import React from "react";
import styles from "./styles.module.scss";
import InstallationHint from "../../InstallationHint";
import InstallationNextButton from "../../InstallationNextButton";
import InstallationGoBackButton from "../../InstallationGoBackButton";
import InstallNote from "../InstallNote";
import InstallDescription from "../InstallDescription";

const MobileGuideBody = ({
  instructions,
  setShowAnimation,
  setMotionMethod,
  motionMethod,
  isMotion,
  isHub,
}) => {
  return (
    <div>
      <ol className={styles.instructions_steps}>
        {instructions?.map((item, index) => {
          if (item?.text) {
            return <li key={index}>{item.text}</li>;
          }
          if (item?.hint) {
            return <InstallationHint hints={item.hint} key={index} />;
          }
          if (item?.image) {
            return (
              <div style={{ marginTop: "12px", marginBottom: "16px" }}>
                <img
                  key={index}
                  src={item.image.image}
                  alt={item.image.alt}
                  style={{
                    maxHeight: `${item?.image.height}px`,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
            );
          }
          if (item?.note) {
            return <InstallNote note={item.note} key={index} />;
          }
          if (item?.description) {
            return (
              <InstallDescription description={item.description} key={index} />
            );
          }
          return null;
        })}
        <p>
          If you’re having any issues please contact support on
          support@intelligentlilli.com
        </p>
      </ol>
      <InstallationNextButton
        label="Back to install"
        onClick={() => {
          if (isHub) {
            setShowAnimation(false);
          } else {
            setMotionMethod(null);
            setShowAnimation(false);
          }
        }}
        marginTop="16px"
      />
      {isMotion && motionMethod && (
        <InstallationGoBackButton
          label="Install options"
          onClick={() => setMotionMethod(null)}
          marginTop="16px"
        />
      )}
    </div>
  );
};

export default MobileGuideBody;
