import React from "react";
import InstallImage from "../InstallImage";
import InstallInstruction from "../InstallInstruction";
import InstallNote from "../InstallNote";

const InstallInstructionsBlock = ({ instruction, maxImageHeight }) => {
  if (instruction?.note) {
    return <InstallNote note={instruction.note} />;
  } else if (instruction?.type === "image") {
    return <InstallImage image={instruction} maxHeight={maxImageHeight} />;
  } else {
    return <InstallInstruction instruction={instruction} />;
  }
};

export default InstallInstructionsBlock;
