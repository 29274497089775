import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceUserEvents } from "../../State/slices/serviceUsersData-slice";
import { SERVER } from "../config";
import { getHubBehavioursEventlog } from "@intelligentlilli/api-layer";
import {
  differenceInMinutes,
  endOfDay,
  format,
  isSameDay,
  startOfDay,
  subDays,
} from "date-fns";

export function useFetchEventLog(serviceUserId, endDateString) {
  const dispatch = useDispatch();
  const serviceUsersData = useSelector((state) => state.serviceUsersData);
  const endDate = endOfDay(new Date(endDateString));
  const dateKey = endDateString; // "yyyy-MM-dd" format
  const prevDayKey = format(subDays(endDate, 1), "yyyy-MM-dd");
  const serviceUserData = serviceUsersData?.[serviceUserId];
  const lastFetchedTime = serviceUserData?.events?.[dateKey]?.lastFetched;
  const needCurrentDaysEvents =
    !lastFetchedTime ||
    (!serviceUserData?.events?.[dateKey]?.isComplete &&
      differenceInMinutes(new Date(), new Date(lastFetchedTime)) > 5);
  const needPreviousDaysEvents =
    !serviceUserData?.events?.[prevDayKey]?.isComplete;

  // The first useEffect hook handles fetching the current day's events (the day the user is looking at)
  useEffect(() => {
    const endDate2 = endOfDay(new Date(endDateString));
    const startDate = startOfDay(endDate2);
    const dateKey2 = endDateString; // "yyyy-MM-dd" format
    const startDateISOString = startDate.toISOString();
    const endDateISOString = endDate2.toISOString();

    const fetchEventLog = async () => {
      if (needCurrentDaysEvents) {
        try {
          const result = await getHubBehavioursEventlog(
            SERVER,
            serviceUserId,
            startDateISOString,
            endDateISOString,
            "web"
          );
          const events = result.body?.events;
          if (events) {
            const now = new Date();
            const augmentedEvents = {
              ...events,
              isComplete: !isSameDay(now, new Date(endDateString)),
              lastFetched: now.toISOString(),
            };
            dispatch(
              updateServiceUserEvents({
                hubId: serviceUserId,
                key: dateKey2,
                events: augmentedEvents,
              })
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    void fetchEventLog();
  }, [endDateString, serviceUserId, dispatch, needCurrentDaysEvents]);

  // The second useEffect hook handles fetching the previous day's events (the day before the day the user is looking at)
  useEffect(() => {
    const endDate3 = endOfDay(new Date(endDateString));
    const startDate = startOfDay(endDate3);
    const prevDayKey2 = format(subDays(endDate3, 1), "yyyy-MM-dd");
    const prevStartDateISOString = subDays(startDate, 1).toISOString();
    const prevEndDateISOString = subDays(endDate3, 1).toISOString();

    const fetchEventLog = async () => {
      if (needPreviousDaysEvents) {
        try {
          const result = await getHubBehavioursEventlog(
            SERVER,
            serviceUserId,
            prevStartDateISOString,
            prevEndDateISOString
          );
          const events = result.body?.events;
          if (events) {
            const now = new Date();
            const augmentedEvents = {
              ...events,
              isComplete: true,
              lastFetched: now.toISOString(),
            };
            dispatch(
              updateServiceUserEvents({
                hubId: serviceUserId,
                key: prevDayKey2,
                events: augmentedEvents,
              })
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    void fetchEventLog();
  }, [endDateString, serviceUserId, dispatch, needPreviousDaysEvents]);
}
