import React from "react";
import styles from "./styles.module.scss";
import theme from "../../../Styles/theme.scss";
import {
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "../../../Styles/Icons/DesignSystem";

const InstallSensorsSubHeaderStatus = ({
  isDesktop,
  installationComplete,
  allSensorsOnline,
}) => {
  return (
    <div className={isDesktop ? styles.icon_desktop : styles.icon}>
      {installationComplete ? (
        <>
          <ThumbsUpIcon color={theme.expected2} width={isDesktop ? 35 : 25} />
          <div className={styles.icon_text}>Almost there!</div>
        </>
      ) : allSensorsOnline ? (
        <>
          <ThumbsUpIcon color={theme.expected2} width={isDesktop ? 35 : 25} />
          <div className={styles.icon_text}>Great!</div>
        </>
      ) : (
        <>
          <ThumbsDownIcon color={theme.risk4} width={isDesktop ? 35 : 25} />
          <div className={styles.icon_text}>Oh no!</div>
        </>
      )}
    </div>
  );
};

export default InstallSensorsSubHeaderStatus;
