import React from "react";
import theme from "../../theme.scss";

export const PersonIcon = ({ width, colour }) => {
  return (
    <svg
      width={width || 21}
      height={width || 27}
      viewBox="0 0 21 27"
      id="PersonIcon"
    >
      <path
        d="M10.5006 13.5002C14.1824 13.5002 17.1673 10.5152 17.1673 6.83343C17.1673 3.15162 14.1824 0.166687 10.5006 0.166687C6.81878 0.166687 3.83385 3.15162 3.83385 6.83343C3.83749 10.5137 6.82034 13.4965 10.5006 13.5002ZM10.5006 2.38911C12.9553 2.38911 14.9449 4.37923 14.9449 6.83343C14.9449 9.28762 12.9548 11.2777 10.5006 11.2777C8.04639 11.2777 6.05626 9.28762 6.05626 6.83343C6.05626 4.37923 8.04639 2.38911 10.5006 2.38911Z"
        fill={colour || theme.neutral5}
      />
      <path
        d="M10.5005 15.7223C4.98016 15.7285 0.506677 20.202 0.500427 25.7224C0.500427 26.3359 0.997782 26.8334 1.61133 26.8334C2.22488 26.8334 2.72228 26.3359 2.72228 25.7224C2.72228 21.427 6.20462 17.9447 10.5 17.9447C14.7953 17.9447 18.2777 21.427 18.2777 25.7224C18.2777 26.3359 18.7751 26.8334 19.3886 26.8334C20.0022 26.8334 20.4996 26.3359 20.4996 25.7224C20.4933 20.202 16.0198 15.7285 10.4995 15.7223H10.5005Z"
        fill={colour || theme.neutral5}
      />
    </svg>
  );
};
