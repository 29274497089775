import styles from "./styles.module.scss";

const Square = ({ sensor, selectedSensor, onClick }) => {
  const isConnectedAndSelected =
    sensor.online && sensor.label === selectedSensor.label;
  const isConnectedAndNotSelected =
    sensor.online && sensor.label !== selectedSensor.label;
  const isNotConnectedAndSelected =
    !sensor.isConnected && sensor.label === selectedSensor.label;

  return (
    <>
      <div
        onClick={() => onClick(sensor)}
        className={
          isConnectedAndSelected
            ? styles.connected_selected // green square, primary3 underline
            : isConnectedAndNotSelected
              ? styles.connected_not_selected // green square, no underline
              : isNotConnectedAndSelected
                ? styles.not_connected_selected // white square, primary3 underline
                : styles.not_connected_not_selected // white square, no underline
        }
      >
        <div className="sensor_square_initial">
          {(sensor?.label).toUpperCase()}
        </div>
        <div
          className={
            sensor.label === selectedSensor.label
              ? styles.underline
              : styles.no_underline
          }
        ></div>
      </div>
    </>
  );
};

export default Square;
