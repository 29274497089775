import React from "react";
import styles from "./styles.module.scss";
import { getInitialsFromNames } from "@intelligentlilli/lilli-utils";
// Components
import Card from "../Card";
import ServiceUserImage from "../ServiceUserImage";
// Utils
import {
  getIsUserJustAnInstaller,
  getUserHasInstallerRole,
  getUserRoleAsString,
} from "../../Services/Utils";

const StaffCard = ({ staffMember, onClick }) => {
  const staffName =
    staffMember?.forenames && staffMember?.surname
      ? staffMember?.forenames + " " + staffMember?.surname
      : staffMember?.username;
  const staffRoles = staffMember?.roles;
  const staffRoleAsString = getUserRoleAsString(staffRoles);
  const staffHasInstallerRole = getUserHasInstallerRole(staffRoles);
  const staffIsJustAnInstaller = getIsUserJustAnInstaller(staffRoles);
  const displayStaffRole = staffIsJustAnInstaller
    ? "Installer"
    : `${staffRoleAsString}${staffHasInstallerRole ? " & Installer" : ""}`;

  return (
    <Card onClick={onClick} variant="outlined" style={styles.card}>
      <div className={styles.card_top} data-private>
        <ServiceUserImage
          medium
          staff
          initials={getInitialsFromNames(
            staffMember?.forenames,
            staffMember?.surname
          )}
          profilePhotoURL={staffMember.photo}
        />
        <div className={styles.card_right}>
          <div className={styles.card_right_name}>{staffName}</div>
          <div>{displayStaffRole}</div>
          <div className={styles.bold}>
            {staffMember?.hubs?.length} service user
            {staffMember?.hubs?.length === 1 ? "" : "s"}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default StaffCard;
