import { isSameDay } from "date-fns";
export const getV1EventLogBathroomTimestamps = ({
  reduxMoveV3Data,
  urlEndDateString,
  prevEndDateString,
  urlEndDate,
}) => {
  const allTimstamps = [
    ...(reduxMoveV3Data?.dailyResults?.find(
      (day) => day.date === urlEndDateString
    )?.eventLog?.["bathroom.wall.motion"] || []),
    ...(reduxMoveV3Data?.dailyResults?.find(
      (day) => day.date === prevEndDateString
    )?.eventLog?.["bathroom.wall.motion"] || []),
  ];
  return allTimstamps.filter((timeStamp) =>
    isSameDay(new Date(timeStamp), urlEndDate)
  );
};
