import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { useNavigate } from "react-router-dom";
// Components
import ToggleOnOff from "../ToggleOnOff";
// Hooks
import { useViewport } from "../ViewportProvider";
// API
import {
  putNotificationSettings,
  getNotificationSettings,
} from "@intelligentlilli/api-layer";
// State
import { useSelector } from "react-redux";

const UserNotificationSettings = () => {
  const server = useSelector((state) => state.session.server);
  const userId = useSelector((state) => state.user.key);
  const navigate = useNavigate();
  const { width } = useViewport();
  const isLargeScreen = width > 812;

  const [isEnabled, setIsEnabled] = useState(false);
  const [notificationsError, setNotificationsError] = useState(null);

  // When first arrival onto theh page, get the current notification settings
  useEffect(() => {
    getNotificationSettings(server, userId, "web")
      .then((res) => {
        if (!res.ok) {
          if (res.status === 401) {
            navigate("/login");
          }
          setNotificationsError(
            "Could not load settings. Refresh the screen to try again."
          );
          return;
        }
        if (res?.body?.global_enabled) {
          setIsEnabled(res.body.global_enabled);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          navigate("/login");
          return;
        }
        console.log("getNighttimeAlertSettings error:", err);
        setNotificationsError(
          "Could not load settings. Refresh the screen to try again."
        );
      });
  }, [server, userId, navigate]);

  const toggleOn = () => {
    setIsEnabled(true);
    putNotificationSettings(server, userId, true, "web")
      .then((res) => {
        if (!res.ok) {
          console.log("toggleOn response is not OK:", res.body.error);
          setIsEnabled(false);
          setNotificationsError(
            "Could not turn on notifications. Please try again."
          );
        }
        setNotificationsError(""); // reset the error message
      })
      .catch((err) => {
        console.log("putNotificationSettings error:", err);
        setIsEnabled(false);
        setNotificationsError(
          "Could not turn on notifications. Please try again."
        );
      });
  };

  const toggleOff = () => {
    setIsEnabled(false);
    putNotificationSettings(server, userId, false, "web")
      .then((res) => {
        if (!res.ok) {
          console.log("toggleOff response is not OK:", res.body.error);
          setIsEnabled(true);
          setNotificationsError(
            "Could not turn off notifications. Please try again."
          );
        }
        setNotificationsError(""); // reset the error message
      })
      .catch((err) => {
        console.log("putNotificationSettings error:", err);
        setIsEnabled(true);
        setNotificationsError(
          "Could not turn off notifications. Please try again."
        );
      });
  };

  return (
    <div className={styles.notifications_container}>
      <div className={styles.outer_container}>
        <div className={styles.subheading_medium}>Notifications</div>
        <div className={styles.notification_explanation}>
          Set which notifications you receive
        </div>
      </div>
      {isLargeScreen ? (
        <div className={styles.inner_container}>
          <div className={styles.top_section}>
            <div className={styles.column_1}>Type of notification</div>
            <div className={styles.column_2}>How we will notify you</div>
            <div className={styles.column_3}>When we will notify you</div>
            <div className={styles.column_4}></div>{" "}
            {/* TOGGLE - intentionally empty */}
          </div>
          <div className={styles.bottom_section}>
            <div className={styles.column_1}>
              <div className={styles.title}>Lilli user update</div>
              <div className={styles.description}>
                A summary of the latest insights about any changes in your Lilli
                users' behaviours.
              </div>
              <div className={styles.notifications_error_message}>
                {notificationsError ? notificationsError : ""}
              </div>
            </div>
            <div className={styles.column_2}>Email</div>
            <div className={styles.column_3}>8am the next morning</div>
            <div className={styles.column_4}>
              <ToggleOnOff
                isEnabled={isEnabled}
                onToggleOn={() => toggleOn()}
                onToggleOff={() => toggleOff()}
                backgroundColor={theme.support2}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.mobile_section}>
            <div className={styles.mobile_title}>Night-time notifications</div>
            <div className={styles.mobile_description}>
              Includes all changes in behaviour in your daily summary, i.e.
              missed meals.
            </div>
          </div>
          <div className={styles.mobile_notification_delivery}>
            <div className={styles.mobile_details}>
              You will receive an email notification at 8am the next morning.
            </div>
            <div className={styles.notifications_error_message}>
              {notificationsError ? notificationsError : ""}
            </div>
          </div>
          <div className={styles.mobile_toggle}>
            <ToggleOnOff
              isEnabled={isEnabled}
              onToggleOn={() => toggleOn()}
              onToggleOff={() => toggleOff()}
              backgroundColor={theme.support2}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNotificationSettings;
