import React, { useState } from "react";
import styles from "./styles.module.scss";
// Components
import DeviceIdQrReader from "../DeviceIdQrReader";
// Forms
import DeviceIdForm from "../../Forms/DeviceIdForm";
import NewStyleModal from "../NewStyleModal";
import InstallationNextButton from "../InstallationNextButton";

export const ManuallyEnterQRCode = ({
  buttonClass,
  brokenQRCode,
  setBrokenQRCode,
  onLinkDevice,
  devicestringError,
  isDesktop,
}) => {
  const manuallyLinkDevice = (deviceId) => {
    setBrokenQRCode(false);
    onLinkDevice(deviceId);
  };

  const [openModal, setOpenModal] = useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div className={styles.manual_entry}>
      {brokenQRCode && (
        <div className={styles.manual_entry_message}>
          There is a problem with the QR code please enter it manually
        </div>
      )}
      <DeviceIdForm
        buttonClass={buttonClass}
        onSubmit={manuallyLinkDevice}
        error={devicestringError}
        isDesktop={isDesktop}
      />
      <p onClick={toggleModal} className={styles.information_link}>
        Where to find the code
      </p>
      {openModal ? (
        <NewStyleModal showCloseIcon hide={toggleModal}>
          <div className={styles.modal_content}>
            <div className={styles.qr_image}>
              <img
                src="/images/installation/qr-number-location.png"
                alt="Location of QR code"
              />
            </div>
            <InstallationNextButton
              label="Back to installation"
              onClick={toggleModal}
            />
          </div>
        </NewStyleModal>
      ) : null}
    </div>
  );
};

export const ScanQRCode = ({
  setBrokenQRCode,
  devicestringError,
  onLinkDevice,
}) => {
  // We have some stickers on our older boxes with incorrect QR codes.
  // The affected QR codes end in FC0 and so require manual entry.
  const linkAfterCheck = (deviceId) => {
    if (deviceId.includes("FC0")) {
      setBrokenQRCode(true);
    } else {
      onLinkDevice(deviceId);
    }
  };

  return (
    <div className={styles.component} data-private>
      <DeviceIdQrReader onSubmit={linkAfterCheck} error={devicestringError} />
    </div>
  );
};
