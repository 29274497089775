import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// Components
import PrimaryButton from "../../Components/PrimaryButton";
import Page from "../../Components/Page";
import SearchInput from "../../Components/SearchInput";
// Hooks
import { useReportsHook, useFade } from "../../Services/Hooks";
// Icons
import { AddIcon } from "../../Styles/Icons";
// Utils
import PageTitle from "../../Components/PageTitle";
import ReportsTable from "../../Components/ReportsTable";
import CreateNewReportModal from "../../Components/CreateNewReportModal";
import ConfirmDeleteReportModal from "../../Components/ConfirmDeleteReportModal";
import Select from "react-select"; // https://react-select.com/home#getting-started
import theme from "../../Styles/theme.scss";

function Reports() {
  const navigate = useNavigate();

  // redux state
  const server = useSelector((state) => state.session.server);
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const user = useSelector((state) => state.user);
  // Local state
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState({
    value: "Newest",
    label: "Sort by: Newest",
  });

  const [reportsToDisplay, setReportsToDisplay] = useState([]);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [localError, setLocalError] = useState(false);

  // A ref to keep track of when the search value is changing so that sorting can be reset
  const searchValueRef = useRef();

  // custom hooks
  const {
    reportTypes,
    reports,
    deleteSelectedReport,
    closeConfirmModal,
    setCloseConfirmModal,
    createReport,
  } = useReportsHook(server, navigate, setLocalLoading, setLocalError);

  const reportTypesWithV1 = useMemo(
    () => [...reportTypes, { id: "general-report", title: "Behaviour Report" }],
    [reportTypes]
  );

  const reportTypeFilterOptions = [
    {
      value: "All reports",
      label: "Filter by: All reports",
      id: "all_reports",
    },
  ].concat(
    (reportTypesWithV1 || []).map((type) => {
      return {
        value: type.title,
        label: `Filter by: ${type.title}`,
        id: type.id,
      };
    })
  );

  const [filterBy, setFilterBy] = useState(reportTypeFilterOptions[0]);

  // When the user searches or sorts update the reports to display
  useEffect(() => {
    let filteredSortedReports = [...reports];
    // First extract the userstring and convert all report names to lower case as the sorting functions cannot handle a case mix
    filteredSortedReports = filteredSortedReports.map((report) => {
      const userstring = serviceUsers?.find(
        (hub) => hub.id === parseInt(report.entity_id)
      )?.userstring;
      return {
        ...report,
        userstring: userstring,
        userstringLower: userstring?.toLowerCase(),
        userstringArray: userstring?.toLowerCase().split(" "),
      };
    });

    // Remove any reports without a userstring
    filteredSortedReports = filteredSortedReports.filter(
      (report) => report.userstring
    );

    // This function searches an array of strings and determines whether any of them match the search value
    const searchArray = (array, inputValue, inputLength) => {
      let matched = false;
      array?.forEach((string) => {
        if (string.slice(0, inputLength) === inputValue) {
          matched = true;
        }
      });
      return matched;
    };

    // Apply the search
    if (searchValue.length > 0) {
      const inputValue = searchValue.trim().toLowerCase();
      const inputLength = inputValue.length;
      filteredSortedReports = filteredSortedReports.filter(
        (report) =>
          report.userstringLower?.slice(0, inputLength) === inputValue ||
          searchArray(report.userstringArray, inputValue, inputLength)
      );
    }

    // Update the ref for the current search term
    searchValueRef.current = searchValue;

    // Apply the sort by alphabetical userstring
    if (sortBy.value === "Service user A-Z") {
      filteredSortedReports = filteredSortedReports.sort((a, b) => {
        if (a.userstringLower < b.userstringLower) return -1;
        if (a.userstringLower > b.userstringLower) return 1;
        return 0;
      });
    }
    // Apply the sort by newest
    if (sortBy.value === "Newest") {
      filteredSortedReports = filteredSortedReports.sort((a, b) => {
        if (a.created_at < b.created_at) return 1;
        if (a.created_at > b.created_at) return -1;
        return 0;
      });
    }

    // if filterBy is anything other than "All reports" then filter the reports
    if (filterBy.value !== "All reports") {
      filteredSortedReports = filteredSortedReports.filter(
        (report) => report.report_type === filterBy.id
      );
    }

    const reportsWithTitles = filteredSortedReports.map((report) => {
      const title =
        reportTypesWithV1.find((type) => type.id === report.report_type)
          ?.title || null;
      return { ...report, title: title };
    });
    // filter out any without titles
    setReportsToDisplay(reportsWithTitles.filter((report) => report.title));
  }, [
    user.key,
    user.organisation,
    server,
    reports,
    searchValue,
    sortBy,
    serviceUsers,
    filterBy,
    reportTypesWithV1,
  ]);

  // Fade Modal
  const [isCreateReportModalVisible, setShowAnimation, showAnimation] = useFade(
    false,
    150
  ); // make sure this is in sync with the NewStyleModal fade_out transition, which is currently set to 200ms. We want the setTimeout to remove the modal before the fade_out transition ends so that we don't deal with the double flash of the modal (perhaps due to race conditions, its unclear)

  // Custom style for the report type select component
  const selectStyle = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? theme.neutral4 : theme.neutral3,
      padding: "2px 0",
      boxShadow: "none",
      borderRadius: 8,
      color: `${theme.grey4}`,
      marginRight: 8,
      "&:hover": {
        borderColor: theme.neutral4,
      },
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? theme.neutral2 : null,
      color: theme.neutral7,
      // adjust background colour when mouse button is pressed
      ":active": {
        backgroundColor: theme.neutral3,
      },
    }),
  };

  return (
    <Page className={styles.page}>
      <div className={styles.content}>
        {reports.length === 0 && localLoading && (
          <div className={styles.content_empty}>
            <h1>Loading ...</h1>
          </div>
        )}
        {localError && (
          <div className={styles.content_empty}>
            <h1>Error loading reports</h1>
          </div>
        )}
        {reports.length === 0 && !localError && !localLoading && (
          <div className={styles.content_empty}>
            <h1>You haven't created any reports yet</h1>
            <p>
              Create a report for one of your Lilli users and it will show up
              here
            </p>
            <PrimaryButton
              startIcon={<AddIcon />}
              padding="7px 8px"
              style={{ borderRadius: 8 }}
              onClick={() => setShowAnimation(true)} //kicks off a series of actions controlled by the useFade hook
              // when set to True, the useEffect hook then sets the NewModal visibility to true
              // when showAnimation is true, then the class ".fade_in" animation kicks in
            >
              Create report
            </PrimaryButton>
          </div>
        )}
        {reports.length > 0 && (
          <>
            <PageTitle title="Reports" />
            <div className={styles.search}>
              <SearchInput
                data-private
                className={`${styles.search_input}`}
                placeholder="Search"
                value={searchValue}
                setValue={setSearchValue}
              />
              <PrimaryButton
                startIcon={<AddIcon />}
                padding="7px 8px"
                style={{ borderRadius: 8 }}
                onClick={() => setShowAnimation(true)} //kicks off a series of actions controlled by the useFade hook
                // when set to True, the useEffect hook then sets the NewModal visibility to true
                // when showAnimation is true, then the class ".fade_in" animation kicks in
              >
                New Report
              </PrimaryButton>
            </div>
            <div className={styles.filters}>
              <Select
                options={reportTypeFilterOptions}
                formatOptionLabel={(option, { context }) => {
                  if (context === "value") return option.label;
                  return option.value;
                }}
                name="serviceUsers"
                value={filterBy}
                onChange={setFilterBy}
                isClearable={false}
                isSearchable={false}
                menuPlacement={"bottom"}
                styles={selectStyle}
                components={{
                  IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
                }}
              />

              <Select
                options={[
                  { value: "Newest", label: "Sort by: Newest" },
                  {
                    value: "Service user A-Z",
                    label: "Sort by: Service user A-Z",
                  },
                ]}
                formatOptionLabel={(option, { context }) => {
                  if (context === "value") return option.label;
                  return option.value;
                }}
                name="serviceUsers"
                value={sortBy}
                onChange={setSortBy}
                isClearable={false}
                isSearchable={false}
                menuPlacement={"bottom"}
                styles={selectStyle}
                components={{
                  IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
                }}
              />
            </div>

            <ReportsTable
              reportsToDisplay={reportsToDisplay}
              setReportToDelete={setReportToDelete}
              setCloseConfirmModal={setCloseConfirmModal}
            />
          </>
        )}
      </div>
      {/* New report */}
      {isCreateReportModalVisible && (
        <CreateNewReportModal
          serviceUsers={serviceUsers}
          reportTypes={reportTypesWithV1}
          showAnimation={showAnimation}
          setShowAnimation={setShowAnimation}
          createReport={createReport}
        />
      )}
      {/* Confirm Delete */}
      {!closeConfirmModal && (
        <ConfirmDeleteReportModal
          setCloseConfirmModal={setCloseConfirmModal}
          reportToDelete={reportToDelete}
          deleteSelectedReport={deleteSelectedReport}
        />
      )}
    </Page>
  );
}

export default Reports;
