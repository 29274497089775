export const temperatureDayAverage = (temperatureData, onClick) => {
  // Simplify temperatureData to just the values
  const temperatureValues = temperatureData?.hourlyData
    ?.map((hour) => hour.averageTemperature)
    .filter((value) => value !== null);

  const hasNoData =
    temperatureData?.hourlyData?.filter((i) => i.averageTemperature !== null)
      .length === 0;

  if (
    !temperatureData ||
    temperatureData?.hourlyData?.length === 0 ||
    hasNoData
  ) {
    return {
      offline: true,
      title: "Avg. Temp. in home",
      icon: "OFFLINE",
      context: "No data available for this insight",
      onClick: onClick,
    };
  }

  // Get average, min and max temperature
  const average =
    temperatureValues &&
    Math.round(
      temperatureValues.reduce((prev, curr) => prev + curr, 0) /
        temperatureValues.length
    );
  const high = temperatureValues && Math.round(Math.max(...temperatureValues));
  const low = temperatureValues && Math.round(Math.min(...temperatureValues));

  const riskLevel = temperatureData?.riskLevel;
  const reasonIsCold = temperatureData?.reason === "cold";
  const reasonIsHot = temperatureData?.reason === "hot";
  const overallTempStatus =
    riskLevel === 2 && reasonIsCold
      ? "Too cold"
      : riskLevel === 2 && reasonIsHot
        ? "Too hot"
        : "Normal";
  return {
    title: "Avg. Temp. in home",
    headline: `${overallTempStatus}: ${average}°C`,
    icon: "TEMPERATURE",
    context: `With highs of ${high}°C, and lows of ${low}°C`,
    onClick: onClick,
  };
};
