import { useState, useEffect, useCallback } from "react";
import { SERVER } from "../config";
import {
  getCheckAuthState,
  getOrganisationHubs,
} from "@intelligentlilli/api-layer";
// State
import { setUser } from "../../State/slices/user-slice";
import { setServiceUsers } from "../../State/slices/serviceUsers-slice";
import { useDispatch } from "react-redux";

export const useNativeInstallAuth = ({ path }) => {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState(null);
  console.log("useNativeInstallAuth auth:", auth);
  const [cloudFlareCookieHeaders, setCloudFlareCookieHeaders] = useState(false);
  const isNative = !!auth;
  const POLLING_INTERVAL = 15000; // Every 15 seconds

  // Grab the token from sessionStorage and set it in state
  // This is set inside of the React Native app, via the Web View component and injected javascript
  useEffect(() => {
    if (window?.sessionStorage) {
      const token = window.sessionStorage.getItem("token");
      console.log("Token from sessionStorage:", token);
      setAuth(token);
    } else {
      console.log("No window object, could not get token");
      setAuth(null);
    }
  }, []);

  // This synthetic API is intercepted by Cloudflare (the 'native-install-auth' worker);
  // This worker takes the cookie (defined in 'auth' variable) and sets it globally, in Cloudflare, against all requests with the domain '.intelligentlilli.com'
  // This means that any API call, regardless of which worker intercepts that route, will have the cookie set in its headers as it goes out
  // (note: this 'works' because Cloudflare is our DNS resolver)
  useEffect(() => {
    if (auth) {
      const setCookieHeadersInCloudFlare = async () => {
        try {
          const response = await fetch(`${SERVER}/${path}`, {
            method: "GET",
            credentials: "include",
            headers: { "install-auth": auth },
          });
          if (response.ok) setCloudFlareCookieHeaders(true);
        } catch (error) {
          console.log("setCookieHeadersInCloudFlare error:", error);
        }
      };

      setCookieHeadersInCloudFlare();
      const interval = setInterval(() => {
        setCookieHeadersInCloudFlare();
      }, POLLING_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [auth, path]);

  // Dev note:
  // The following functions (getOrgHubs, getAuthData) are used inside of the below useEffect hook
  // these functions are kept outside of useEffect for: readability, reusability, testability, and to avoid unnecessary re-renders
  // useCallback is used to memoize the functions so that they are not recreated on every render
  // getOrgHubs dependency array is empty because SERVER is a constant and we can ignore arguments passed to the function
  // getAuthData dependency array must reference getOrgHubs even though its function definition won't change because of
  //     react's rule of hooks and to make explicit dependency relationships, in case future devs change that function and it is no longer a stable reference
  const getOrgHubs = useCallback(async (orgID) => {
    try {
      const response = await getOrganisationHubs(SERVER, orgID, "mobile-app");
      if (response.ok && response.body) {
        return response.body;
      }
      console.log("Error fetching organisation hubs:", response.status);
    } catch (error) {
      console.log("Error in getOrgHubs:", error);
    }
    return null;
  }, []);

  const getAuthData = useCallback(async () => {
    try {
      const response = await getCheckAuthState(SERVER, "mobile-app");
      if (response.ok && path === "install/native/start") {
        dispatch(setUser(response.body));
        console.log("getCheckAuthState response.body:", response.body);

        const { organisationId, roles = [], hubs = [] } = response.body;

        if (roles.includes("manager") || roles.includes("admin")) {
          console.log("dispatch orgHubs from", { organisationId });
          const orgHubs = await getOrgHubs(organisationId);
          return orgHubs
            ? dispatch(setServiceUsers(orgHubs))
            : dispatch(setServiceUsers(hubs));
        } else {
          console.log("dispatch hubs from auth data:", hubs);
          return dispatch(setServiceUsers(hubs));
        }
      }
    } catch (error) {
      console.log("Error in getAuthData:", { path, error });
    }
  }, [getOrgHubs, path, dispatch]);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    if (isNative && cloudFlareCookieHeaders) {
      // You cannot call "await getAuthData()" inside a useEffect hook,
      // It must be called from an async function that was declared inside the useEffect hook (current option)
      // Or wrap the getAuthData() func in a IFFE (immediately invoked function expression)
      const fetchAuthData = async () => {
        console.log("** useEffect:", { isNative, cloudFlareCookieHeaders });
        if (isMounted) {
          await getAuthData();
        }
      };

      fetchAuthData();
    }

    return () => {
      isMounted = false;
    }; // Cleanup function
  }, [isNative, cloudFlareCookieHeaders, getAuthData]);

  return { isNative };
};
