import React from "react";
import styles from "./styles.module.scss";
import SensorSignal from "../SensorSignal";
import SensorSticker from "../SensorSticker";

const Sensor = ({
  device,
  onClick,
  sensorLocation,
  loading,
  ignoreLocation,
}) => {
  const placementText = !sensorLocation ? "Select a location" : sensorLocation;

  return (
    <div
      className={`${styles.sensor} ${onClick && styles.sensor_click}`}
      onClick={onClick}
    >
      <div className={styles.sensor_left}>
        <SensorSticker
          colour={device.colour}
          labelType={device.labelType}
          label={device.label}
        />
        <div className={styles.sensor_details}>
          <div className={styles.sensor_name}>
            {device.displayName || device.defaultName}
          </div>
          {!ignoreLocation && (
            <div
              className={`${!sensorLocation && styles.no_sensor_location} ${
                styles.sensor_location
              }`}
            >
              Placement: {placementText}
            </div>
          )}
          <div className={styles.sensor_signal}>
            <SensorSignal online={device?.online} loading={loading} />
          </div>
        </div>
      </div>

      <img src={device.img} className={styles.image} alt="sensor" />
    </div>
  );
};

export default Sensor;
