import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
// Hooks
import { useDownloadReport, useIsMounted } from "../../Services/Hooks";
// Components
import { useViewport } from "../ViewportProvider";
import PrimaryButton from "../PrimaryButton";
import ButtonSpinner from "../Spinners/ButtonSpinner";
// Icons
import {
  KebabMenuIcon,
  DownloadIcon,
  DeleteIcon,
  CircledTickIcon,
} from "../../Styles/Icons/DesignSystem";
// Utils
import { format, parseISO } from "date-fns";
import { downloadReport, openInNewTab } from "@intelligentlilli/lilli-utils";
// API calls
import {
  getReportStatusAndLocationV1,
  getReportStatusAndLocationV2,
  postPublishReportV1,
  postPublishReportV2,
} from "@intelligentlilli/api-layer";

const Report = ({
  report,
  setCloseConfirmModal,
  setReportToDelete,
  moveModalUp,
}) => {
  const server = useSelector((state) => state.session.server);
  const navigate = useNavigate();

  const { width } = useViewport();
  const smallScreen = width < 600;

  const [showMenu, setShowMenu] = useState(null);
  const [downloadingReport, setDownloadingReport] = useState();
  const [error, setError] = useState(false);

  /* 
    The following isMounted ref makes sure the async call to publish the report does not produce 
    memory leaks should the component be ummounted before the promise resolves
  */
  const isMounted = useIsMounted();

  // Extract required report information to display
  const dateReportWasCreated = report.created_at
    ? format(parseISO(report.created_at), "dd-LL-yy")
    : "";

  const startDate =
    report.start_at && format(parseISO(report.start_at), "dd-LL-yy");

  // We add one less day than than the number of days as the start and end dates are inclusive in the report
  const endDate = report.end_at && format(parseISO(report.end_at), "dd-LL-yy");

  const onDelete = (e) => {
    // Prevent the click event bubbling up to the parent element
    e.stopPropagation();
    setCloseConfirmModal(false);
    setReportToDelete(report);
  };

  const { reportURL, setReportURL, getButtonText } = useDownloadReport({
    smallScreen,
    error,
    downloadingReport,
  });

  const previewReport = () => {
    if (report.report_type === "general-report") {
      navigate(`/pdf-preview/${report.id}`);
    } else {
      navigate(`/pdf-previewV2/${report.request_id}`);
    }
  };

  const generateAndDownloadReport = (e) => {
    e.stopPropagation();
    if (downloadingReport === true || error) {
      return;
    }
    downloadingReport === false
      ? openInNewTab(reportURL)
      : downloadReport(
          server,
          setDownloadingReport,
          report.report_type === "general-report"
            ? report.id
            : report.request_id,
          setError,
          setReportURL,
          report.report_type === "general-report"
            ? getReportStatusAndLocationV1
            : getReportStatusAndLocationV2,
          report.report_type === "general-report"
            ? postPublishReportV1
            : postPublishReportV2,
          isMounted
        );
  };

  return (
    <tr
      className={styles.report}
      cypresstestid="single_report"
      onClick={() => previewReport()}
      onMouseLeave={() => {
        setShowMenu(null);
      }}
    >
      <td className={`${styles.report_element}`} data-private>
        {report?.userstring}
      </td>
      <td className={`${styles.report_element}`} data-private>
        {report?.title}
      </td>
      <td className={styles.report_element}>{dateReportWasCreated}</td>
      <td className={styles.report_element}>{startDate}</td>
      <td className={styles.report_element}>{endDate}</td>
      <td className={styles.report_element_menu}>
        <button
          className={showMenu ? styles.kebab_menu_hover : styles.kebab_menu}
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu(report.request_id || report.id);
          }}
          onMouseOver={() => {
            setShowMenu(report.request_id || report.id);
          }}
        >
          <KebabMenuIcon color={theme.neutral6} />
        </button>
        {showMenu && (
          <div
            className={moveModalUp ? styles.menu_above : styles.menu_below}
            onMouseLeave={() => {
              setShowMenu(null);
            }}
          >
            <PrimaryButton
              onClick={(e) => onDelete(e)}
              label="Delete"
              backgroundColour={theme.white}
              startIcon={<DeleteIcon colour={theme.neutral7} width={14} />}
              hoverColour={theme.neutral2}
              padding={"4px 16px 4px 16px"}
              style={{ textWrap: "noWrap" }}
            >
              Delete report
            </PrimaryButton>
            <PrimaryButton
              onClick={(e) => generateAndDownloadReport(e)}
              label={getButtonText()}
              backgroundColour={
                error
                  ? theme.risk3
                  : downloadingReport === false
                    ? theme.primary3
                    : theme.white
              }
              startIcon={
                downloadingReport ? (
                  <ButtonSpinner />
                ) : error ? null : downloadingReport === false ? (
                  <CircledTickIcon color={theme.neutral7} />
                ) : (
                  <DownloadIcon width={12} colour={theme.neutral7} />
                )
              }
              hoverColour={
                error
                  ? theme.risk2
                  : downloadingReport === false
                    ? theme.primary1
                    : theme.neutral2
              }
            >
              {getButtonText()}
            </PrimaryButton>
          </div>
        )}
      </td>
    </tr>
  );
};

export default Report;
