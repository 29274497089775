import React from "react";
import styles from "./styles.module.scss";
// Components
import NavTab from "../NavTab";
import { useViewport } from "../../Components/ViewportProvider";
// Utils
import { capitalize } from "@intelligentlilli/lilli-utils";

const NavigationTabs = (props) => {
  // TODO - replace this with the new logic when we have knowledge of what sensors a hub has.
  let tabs = [...props.navigationTabs];
  if (
    props.organisationId === 22 // Dorset Council
  ) {
    tabs = tabs.filter((tab) => tab !== "bathroom"); // then don't show the bathroom tab
  }
  // sizing
  const { width } = useViewport();
  // Helper functions
  const getValue = () => {
    return props.activeTab.tab;
  };

  const updateActiveTabState = (newValue) => {
    return props.onChangeNavTab({ ...props.activeTab, tab: newValue });
  };

  // Handle change in Nav Tabs differently depending on Desktop vs Mobile.
  // Mobile view uses 'select' dropdown. Desktop view does not.
  const handleChangeDesktop = (newActiveTab) => {
    updateActiveTabState(newActiveTab);
  };
  const handleChangeMobile = (event) => {
    updateActiveTabState(event.target.value);
  };

  if (width > 600) {
    return (
      <div className={styles.nav_tabs}>
        {tabs.map((tabName, index) => {
          return (
            <NavTab
              key={index}
              label={tabName}
              onChangeNavTab={handleChangeDesktop}
              activeTab={props.activeTab}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className={styles.nav_tabs}>
        <select onChange={handleChangeMobile} value={getValue()}>
          {tabs.map((tabName) => {
            return (
              <option
                key={tabName}
                type="text"
                value={tabName}
                label={
                  tabName.toLowerCase() === "sleep"
                    ? "Night-time"
                    : tabName.toLowerCase() === "sustenance"
                      ? "Eating & Drinking"
                      : tabName.toLowerCase() === "independence"
                        ? "Time Outside"
                        : capitalize(tabName)
                }
              >
                {tabName.toLowerCase() === "sleep"
                  ? "Night-time"
                  : tabName.toLowerCase() === "sustenance"
                    ? "Eating & Drinking"
                    : tabName.toLowerCase() === "independence"
                      ? "Time Outside"
                      : capitalize(tabName)}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
};

export default NavigationTabs;
