import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { sortHigh, sortLow, RiskLevel } from "@intelligentlilli/lilli-utils";
import { getSearchResults, getDaysSinceRiskStatusChanged } from "../Utils";

export const useGetServiceUserList = (selectedFilter, searchValue) => {
  const hasSearchTerm = searchValue?.trim()?.length > 0;
  const searchValueRef = useRef();
  // Redux
  const serviceUsers = useSelector((state) => state.serviceUsers);
  const serviceUsersData = useSelector((state) => state.serviceUsersData);
  const staff = useSelector((state) => state.staff);
  // Local state
  const [serviceUsersToDisplay, setServiceUsersToDisplay] = useState([]);
  const [sortAZ, setSortAZ] = useState();
  const [sortRisk, setSortRisk] = useState();
  const [sortInstall, setSortInstall] = useState();

  // When the selected filter, search term or sorting preference changes update the service users to display
  useEffect(() => {
    // Function to get the unfiltered list of service users augmented with risk and installation status
    const getBaseSUs = (serviceUsers, serviceUsersData) => {
      // Make a deep copy of the service users so that they can be modified
      const deepSUsCopy = JSON.parse(JSON.stringify(serviceUsers));
      // Add in the risk status of each service user
      return deepSUsCopy.map((serviceUser) => {
        const installationStatus =
          serviceUser?.serviceUserProperties?.installationStatus;
        const riskStatus = serviceUsersData?.[serviceUser.id]?.riskStatus;
        const daysSinceRiskStatusChanged = getDaysSinceRiskStatusChanged(
          serviceUsersData?.[serviceUser.id]?.riskScores,
          serviceUser?.firstEventTimestamp
        );
        return {
          ...serviceUser,
          // For service users that are post install but don't have a risk status (recent installs) add them to the expected SUs.
          riskStatus: riskStatus ?? (installationStatus === "complete" && 0),
          installationStatus: installationStatus || "not started",
          daysSinceRiskStatusChanged: daysSinceRiskStatusChanged,
        };
      });
    };
    let filteredServiceUsers = getBaseSUs(serviceUsers, serviceUsersData) || [];
    // Apply the filter
    if (selectedFilter !== "all") {
      filteredServiceUsers = filteredServiceUsers?.filter((i) => {
        if (
          selectedFilter === "friends_and_family" ||
          "no_friends_and_family"
        ) {
          const doesSUHaveFF = i?.viewers?.some((viewer) => {
            const staffMember = staff?.find(
              (staffItem) => staffItem.id === viewer.id
            );
            return staffMember?.roles[0] === "friend-or-family";
          });
          if (selectedFilter === "friends_and_family") {
            return doesSUHaveFF;
          }
          if (selectedFilter === "no_friends_and_family") {
            return !doesSUHaveFF;
          }
        }

        return RiskLevel[i.riskStatus] === selectedFilter
          ? RiskLevel[i.riskStatus] === selectedFilter
          : selectedFilter === "installation"
            ? i?.installationStatus
            : selectedFilter === "incomplete"
              ? !i?.installationStatus ||
                i?.installationStatus === "not started" ||
                i?.installationStatus === "started"
              : i.installationStatus === selectedFilter;
      });
    }

    // Apply the search
    if (hasSearchTerm) {
      filteredServiceUsers = getSearchResults(
        searchValue,
        filteredServiceUsers
      );
    }

    // When the user is searching, reset the sorting so that the most relevant results always appear at the top
    if (searchValue !== searchValueRef.current) {
      setSortAZ();
      setSortInstall();
      setSortRisk();
    } else {
      // Apply sorting
      if (sortAZ === true) {
        filteredServiceUsers = sortHigh(filteredServiceUsers, "userstring");
      }
      if (sortAZ === false) {
        filteredServiceUsers = sortLow(filteredServiceUsers, "userstring");
      }
      if (sortRisk === true) {
        filteredServiceUsers = sortLow(filteredServiceUsers, "riskStatus");
      }
      if (sortRisk === false) {
        filteredServiceUsers = sortHigh(filteredServiceUsers, "riskStatus");
      }
      if (sortInstall === true) {
        filteredServiceUsers = sortLow(
          filteredServiceUsers,
          "installationStatus"
        );
      }
      if (sortInstall === false) {
        filteredServiceUsers = sortHigh(
          filteredServiceUsers,
          "installationStatus"
        );
      }
    }

    // Update the ref for the current search term
    searchValueRef.current = searchValue;
    setServiceUsersToDisplay(filteredServiceUsers);
  }, [
    serviceUsers,
    serviceUsersData,
    selectedFilter,
    hasSearchTerm,
    searchValue,
    sortInstall,
    setSortRisk,
    setSortInstall,
    setSortAZ,
    sortAZ,
    sortRisk,
    staff,
  ]);

  return {
    serviceUsersToDisplay,
    sortRisk,
    sortAZ,
    sortInstall,
    setSortRisk,
    setSortInstall,
    setSortAZ,
  };
};
