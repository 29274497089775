import { format } from "date-fns";
import { getRangeViewStats } from "../RangeView";
import { getHoursAndMinutesAsString } from "../getHoursAndMinutesAsString";
import {
  DUMMY_SENSOR_DATA_1,
  DUMMY_SENSOR_DATA_2,
} from "../../../Components/BehaviourDayTime/dummydata";

export const movementRangeComparison = (
  movementData,
  dateType,
  previousDataPeriod,
  prevStartDate,
  prevEndDate,
  from,
  to,
  selectedFilter,
  onClick
) => {
  // Calculate insights
  const currentMovementData =
    getRangeViewStats(movementData, selectedFilter, selectedFilter) || [];
  const previousMovementData =
    getRangeViewStats(previousDataPeriod, selectedFilter, selectedFilter) || [];

  const expectedCurrentMin = currentMovementData?.reduce(
    (total, item) => total + (item?.expectedMin || 0),
    0
  );
  const expectedPreviousMin = previousMovementData?.reduce(
    (total, item) => total + (item?.expectedMin || 0),
    0
  );
  const totalPrev = previousMovementData?.reduce(
    (total, item) => total + (item?.value || 0),
    0
  );
  const totalCurrent = currentMovementData?.reduce(
    (total, item) => total + (item?.value || 0),
    0
  );

  // Amount & Direction of change
  const totalChange = totalCurrent - totalPrev;
  const isIncrease = totalChange > 0;
  const isDecrease = totalChange < 0;

  // Handle percent change when the denominator is 0
  const totalPercentChange =
    totalPrev === 0 && isIncrease
      ? 100
      : totalPrev === 0 && isDecrease
        ? -100
        : ((totalCurrent - totalPrev) / totalPrev) * 100;
  // Format values for display
  const movementChange = isNaN(totalPercentChange)
    ? "?"
    : totalPercentChange >= 1 && totalPercentChange <= -1
      ? totalPercentChange
      : totalPercentChange;
  const cannotCalculate = movementChange === "?";
  const absoluteChange = Math.abs(movementChange);
  const prettyAmountChange = Math.round(absoluteChange);

  const noChange = totalCurrent === totalPrev;
  const amountChange = cannotCalculate
    ? "Not enough data"
    : noChange
      ? "No change"
      : `${prettyAmountChange}%`;

  const displayTextTotalPrev = getHoursAndMinutesAsString(totalPrev);
  const displayTextTotalCurrent = getHoursAndMinutesAsString(totalCurrent);
  const insightContext = cannotCalculate
    ? `There is not enough data to analyse how much movement has changed since last ${dateType}.`
    : noChange
      ? `Total movement has not changed compared to last ${dateType}: ${displayTextTotalPrev} vs ${displayTextTotalCurrent} events: `
      : `Total movement is ${
          isIncrease ? "up" : "down"
        } ${prettyAmountChange}% compared to last ${dateType}: `;
  const insightCompareValues = `${displayTextTotalPrev} vs ${displayTextTotalCurrent}`;

  const hasNoData =
    movementData?.filter(
      (i) =>
        i?.durationMinutes?.dayTime >= 0 && i?.durationMinutes?.nightTime >= 0
    )?.length === 0;
  const hasNoPreviousData =
    previousDataPeriod?.filter(
      (i) =>
        i?.durationMinutes?.dayTime >= 0 && i?.durationMinutes?.nightTime >= 0
    )?.length === 0;
  // -------
  const daysWithNoData = movementData?.filter(
    (i) =>
      i?.durationMinutes?.dayTime === null &&
      i?.durationMinutes?.nightTime === null
  )?.length;

  const previousDaysWithNoData = previousDataPeriod?.filter(
    (i) =>
      i?.durationMinutes?.dayTime === null &&
      i?.durationMinutes?.nightTime === null
  )?.length;

  // Modal data
  const currentDataModal = {
    daysWithNoData: daysWithNoData,
    hasNoData: hasNoData,
    timePeriod: `${format(new Date(from), "dd MMM")} - ${format(
      new Date(to),
      "dd MMM"
    )}`,
    label: "",
    value: totalCurrent,
    percentChange: cannotCalculate
      ? "Not enough data"
      : noChange
        ? "No change"
        : `${isIncrease ? "+" : "-"}${prettyAmountChange}%`,
    expected: expectedCurrentMin,
    sensors: DUMMY_SENSOR_DATA_1,
  };
  const previousDataModal = {
    daysWithNoData: previousDaysWithNoData,
    hasNoData: hasNoPreviousData,
    timePeriod: `${format(new Date(prevStartDate), "dd MMM")} - ${format(
      new Date(prevEndDate),
      "dd MMM"
    )}`,
    label: "",
    value: totalPrev,
    percentChange: 0,
    expected: expectedPreviousMin,
    sensors: DUMMY_SENSOR_DATA_2,
  };

  if (!true) {
    return {
      offline: true,
      title: `Compared to last ${dateType}`,
      icon: "OFFLINE",
      context: "No data available for this insight",
    };
  }

  return {
    onClick: onClick,
    children: {
      type: "MOVEMENT_COMPARISON",
      noChange,
      isIncrease,
      isDecrease,
      cannotCalculate,
      amountChange,
      dateType,
      insightContext,
      insightCompareValues,
      daysWithNoData,
      hasNoData,
    },
    previousDataModal,
    currentDataModal,
  };
};
