export const Starting_Instructions = [
  "1. Plug in the smart plug first and then press the LED button on the side. A green light should come on.",
  {
    note: "For the purpose of getting it online, plug it into any power socket. Doing this first helps the other sensors connect faster.",
  },
  {
    type: "image",
    source: "/images/installation/sensors/plug/plug-on.png",
    alt: "Smart plug plugged into power outlet and green light on",
  },
  "2. Insert batteries into the remaining sensors.",
  "3. Only when all batteries are in should you click 'Test connection'.",
];

export const Locations_Required = [
  "All the sensors are online.",
  "Next, select the location for each sensor below.",
];

const doNotCloseApp = "Do not close the app while you complete this step.";

export const Not_All_Sensors_Online = [
  "Not all the sensors came online. Click 'Need help?' to troubleshoot.",
  doNotCloseApp,
];
