import React from "react";
import styles from "./styles.module.scss";
import { useViewport } from "../ViewportProvider";
import { mobileBreakpoint } from "../../Services/config";
import { BehaviourTypeIcon } from "../../Services/Utils/Constants/behaviours";
import {
  AtRiskInsightIcon,
  LearnMoreIcon,
  NoDataIcon,
} from "../../Styles/Icons/DesignSystem";
import InsightMovementDonut from "../InsightMovementDonut";
import InsightSplitLeftRight from "../InsightSplitLeftRight";
import InsightMovementComparison from "../InsightMovementComparison";
import InsightBathroomComparison from "../InsightBathroomComparison";
import InsightBathroomDuration from "../InsightBathroomDuration";
import InsightBathingDuration from "../InsightBathingDuration";
import InsightNighttimeComparison from "../InsightNighttimeComparison";

const Insight = ({ insight }) => {
  const { width } = useViewport();
  const onMobileView = width <= mobileBreakpoint;

  // Get the required icon
  let Icon;
  if (insight.icon === "LEARN_MORE") {
    Icon = LearnMoreIcon;
  } else if (insight.icon === "OFFLINE") {
    Icon = NoDataIcon;
  } else if (insight.children?.type === "BATHING_DURATION") {
    Icon = BehaviourTypeIcon["BATHING"];
  } else if (insight.icon === "AT_RISK_INSIGHT") {
    Icon = AtRiskInsightIcon;
  } else {
    const behaviourType = insight?.icon;
    Icon = BehaviourTypeIcon[behaviourType];
  }

  const riskScore = insight.riskLevel || 0;

  return (
    <div
      className={`${
        riskScore === 2
          ? styles.insight_at_risk
          : riskScore === 1
            ? styles.insight_unexpected
            : styles.insight
      } ${insight.offline ? styles.insight_offline : ""} ${
        insight.onClick && !onMobileView ? styles.insight_hover : ""
      }`}
      onClick={!onMobileView ? insight.onClick : null}
    >
      {insight.title && (
        <div className={styles.insight_top}>
          <Icon width={18} viewbox={"0 0 18 18"} />
          <h3>{insight.title}</h3>
        </div>
      )}
      {insight.headline && (
        <div className={styles.insight_headline}>{insight.headline}</div>
      )}
      {insight.children?.type === "MOVEMENT_DONUT" && (
        <InsightMovementDonut
          totalMovements={insight.children.totalMovements}
          totalMovementsString={insight.children.totalMovementsString}
          expectedMovements={insight.children.expectedMovements}
          expectedMovementsString={insight.children.expectedMovementsString}
          riskScore={riskScore}
        />
      )}
      {insight.children?.type === "SPLIT_INSIGHT" && (
        <InsightSplitLeftRight
          left={insight.children.left}
          right={insight.children.right}
        />
      )}
      {insight.children?.type === "BATHROOM_DURATION" && (
        <InsightBathroomDuration
          hours={insight.children.hours}
          minutes={insight.children.minutes}
        />
      )}
      {insight.children?.type === "BATHING_DURATION" && (
        <InsightBathingDuration
          hours={insight.children.hours}
          minutes={insight.children.minutes}
        />
      )}
      {insight.children?.type === "MOVEMENT_COMPARISON" && (
        <InsightMovementComparison {...insight.children} />
      )}
      {insight.children?.type === "BATHROOM_COMPARISON" && (
        <InsightBathroomComparison {...insight.children} />
      )}
      {insight.children?.type === "SLEEP" && (
        <InsightNighttimeComparison {...insight.children} />
      )}
      {typeof insight.context === "string" ? (
        insight.context && <p data-private>{insight.context}</p>
      ) : (
        <p data-private>
          {insight?.context?.map((item) => {
            const { text, type } = item;

            if (type === "bold") {
              return <span style={{ fontWeight: "bold" }}>{text}</span>;
            }
            if (type === "break") {
              return <br></br>;
            } else {
              return <span>{text}</span>;
            }
          })}
        </p>
      )}
    </div>
  );
};

export default Insight;
