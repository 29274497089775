import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import {
  EyeIcon,
  HideIcon,
  AtRiskInsightIcon,
} from "../../Styles/Icons/DesignSystem";
import { IconButton } from "@mui/material";

const FormInput = ({
  input,
  label,
  isPassword,
  showPassword,
  setShowPassword,
  placeholder,
  meetsAllCritera,
  setValue,
  hasInput,
  isActive,
}) => {
  // To access the input value outside of the form, do it here,
  // with the help of setState
  // This is used in the ResetPasswordForm
  useEffect(() => {
    const value = input?.value;
    if (!setValue) {
      return;
    } else {
      setValue(value);
    }
  }, [input, setValue]);

  return (
    <>
      <div className={styles.input_container}>
        <div>{label}</div>
        <div
          className={
            !hasInput
              ? styles.input_inner_container
              : meetsAllCritera
                ? styles.meets_criteria
                : styles.does_not_meet_criteria
          }
        >
          <input
            {...input}
            type={
              (isPassword && showPassword) || !isPassword ? "text" : "password"
            }
            placeholder={placeholder}
          />
        </div>
        {/* 
        If the input is password, then the position of this icon is changed
       */}
        {hasInput && !isActive && !meetsAllCritera && (
          <IconButton
            className={isPassword ? styles.incorrect_icon : styles.icon}
          >
            <AtRiskInsightIcon size={14} />
          </IconButton>
        )}
        {isPassword && (
          <IconButton
            onClick={() => setShowPassword((isShown) => !isShown)}
            className={styles.icon}
          >
            {showPassword ? (
              <HideIcon colour={theme.neutral7} />
            ) : (
              <EyeIcon colour={theme.neutral7} />
            )}
          </IconButton>
        )}
      </div>
    </>
  );
};

export default FormInput;
