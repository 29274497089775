import React from "react";
import styles from "./styles.module.scss";

const RiskStatusTip = ({ containerClassName, topClassName }) => {
  const unexpected =
    "This user has recently shown a slight change in their normal behaviour";
  const atRisk =
    "This user has shown a change in their eating and drinking behaviour which suggests their health may be at risk";

  const atRiskTips = [
    {
      key: "unexpected",
      label: "Unexpected",
      description: unexpected,
    },
    {
      key: "atRisk",
      label: "At risk",
      description: atRisk,
    },
  ];

  return (
    <div className={containerClassName || styles.risk_status_container}>
      {atRiskTips.map((item, index) => (
        <div className={styles.status_container} key={index}>
          <div className={topClassName || styles.top}>
            <div
              className={
                item.key === "unexpected" ? styles.unexpected : styles.at_risk
              }
            ></div>
            <div className={styles.label}>{item.label}</div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.placeholder}></div>
            <div className={styles.risk_status_description}>
              {item.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RiskStatusTip;
