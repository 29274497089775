import React from "react";

const InstallImage = ({ image, maxHeight }) => {
  return (
    <div style={{ marginTop: "8px", marginBottom: "16px" }}>
      <img
        src={image.source}
        alt={image.alt}
        style={{
          maxHeight: `${maxHeight || "120px"}`,
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      />
    </div>
  );
};

export default InstallImage;
