import React from "react";
import styles from "./styles.module.scss";
import { format } from "date-fns";
import { useViewport } from "../ViewportProvider";

const RiskStats = ({ text }) => {
  return <div className={styles.details_risk_status}>{text}</div>;
};

const BelowOrAboveStats = ({
  isTablet,
  firstText,
  secondText,
  isHighlighted,
}) => {
  return (
    <>
      {!isTablet && (
        <div className={isHighlighted ? styles.neutral7 : styles.neutral5}>
          {firstText}
        </div>
      )}
      <div
        className={
          secondText === "No data"
            ? styles.dark_text
            : isHighlighted && firstText !== "Too hot"
              ? styles.lighter_text
              : isHighlighted && firstText === "Too hot"
                ? styles.too_hot_contrast
                : styles.neutral5
        }
      >
        {secondText}
      </div>
    </>
  );
};

const CalendarDay = ({
  noData,
  riskLevel,
  firstLineText,
  secondLineText,
  isHighlighted,
  highlightColour,
  isBlank,
  date,
  selection,
  isMobile,
  changePage,
  activeTab,
}) => {
  const { width } = useViewport();
  const isTablet = width < 1000;

  const selectedRisk = selection?.key === "risk";

  const dayDate = isBlank ? null : date ? new Date(date) : null;
  const formattedDate = !dayDate ? null : format(dayDate, "d");
  const goToDate = (data) => {
    if (data && dayDate !== null) {
      changePage(activeTab, new Date(dayDate), new Date(dayDate), "day");
    }
  };

  // Note: highlightColour is passed via styles={{}} in the parent component

  // TO DO: refactor styling such that all background colors are passed via: "highlightColour" prop

  // To DO: remove riskLevel calculations from the style conditionals below;
  // riskLevel = dayRating and we have dayRating in the condenseAndChunk function

  // Styling
  const styleDesktop = isBlank
    ? styles.blank_square_desktop
    : selectedRisk && riskLevel === 2
      ? styles.at_risk_desktop
      : selectedRisk && riskLevel === 1
        ? styles.unexpected_desktop
        : selectedRisk && riskLevel === 0
          ? styles.expected_desktop
          : (!riskLevel && firstLineText === null) || noData
            ? styles.no_data_desktop
            : isHighlighted && firstLineText === "Too cold"
              ? styles.too_cold_desktop
              : isHighlighted && firstLineText === "Too hot"
                ? styles.too_hot_desktop
                : isHighlighted
                  ? styles.not_expected_desktop
                  : styles.expected_desktop;

  const styleMobile = isBlank
    ? styles.blank_square_mobile
    : selectedRisk && riskLevel === 2
      ? styles.at_risk_mobile
      : selectedRisk && riskLevel === 1
        ? styles.unexpected_mobile
        : selectedRisk && riskLevel === 0
          ? styles.expected_mobile
          : (!riskLevel && firstLineText === null) || noData
            ? styles.no_data_mobile
            : isHighlighted && firstLineText === "Too cold"
              ? styles.too_cold_mobile
              : isHighlighted && firstLineText === "Too hot"
                ? styles.too_hot_mobile
                : isHighlighted
                  ? styles.not_expected_mobile
                  : styles.expected_mobile;

  // Date Styling
  const dateDesktop =
    selectedRisk && riskLevel >= 1
      ? styles.date_at_risk_desktop
      : selectedRisk && riskLevel < 1
        ? styles.date_not_at_risk_desktop
        : isHighlighted
          ? styles.date_not_expected_desktop
          : styles.date_not_at_risk_desktop;

  const dateMobile =
    (selectedRisk && riskLevel >= 1) || isHighlighted
      ? styles.date_at_risk_mobile
      : styles.date_not_at_risk_mobile;

  const riskText =
    riskLevel > 0
      ? "Less than expected"
      : riskLevel === 0
        ? "Expected"
        : "No data";

  return (
    <div
      className={isMobile ? styleMobile : styleDesktop}
      onClick={goToDate}
      style={{
        backgroundColor: highlightColour,
      }}
    >
      <div className={isMobile ? dateMobile : dateDesktop}>
        {formattedDate ? formattedDate : ""}
      </div>
      {!isBlank && !isMobile && (
        <div className={styles.details}>
          {selectedRisk ? (
            <RiskStats text={riskText} />
          ) : (
            <BelowOrAboveStats
              isTablet={isTablet}
              firstText={firstLineText}
              secondText={secondLineText}
              isHighlighted={isHighlighted}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarDay;
