import React from "react";
import theme from "../../Styles/theme.scss";

// This function takes a polar coordinnate and converts it to cartesian coordinates
const polarToCartesian = (center, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees * 2 * Math.PI) / 360;
  return {
    x: center - radius * Math.sin(angleInRadians),
    y: center + radius * Math.cos(angleInRadians),
  };
};

const Donut = ({
  percentage,
  hidePerecentageText,
  percentChange,
  riskScore,
  isModelReady,
}) => {
  const GREY =
    riskScore === 0
      ? theme.secondary1
      : riskScore === 1
        ? theme.unexpected1
        : theme.risk1;
  const ACCENT =
    riskScore === 0
      ? theme.secondary3
      : riskScore === 1
        ? theme.unexpected3
        : theme.risk3;
  const DARK_ACCENT =
    riskScore === 0
      ? theme.secondary3
      : riskScore === 1
        ? theme.unexpected3
        : theme.risk3;
  const CHART_WIDTH = 70;
  const STROKE_WIDTH = 12;
  const FONT_SIZE = 12;

  const chartWidth = percentChange ? CHART_WIDTH * percentChange : CHART_WIDTH;
  const strokeWidth = percentChange
    ? STROKE_WIDTH * percentChange
    : STROKE_WIDTH;
  const fontSize = percentChange ? FONT_SIZE * percentChange : FONT_SIZE;

  const diameter = chartWidth - strokeWidth; // Reduce the diameter by the stroke width of the circle so that it fits within the chart width
  const center = chartWidth / 2;
  const radius = diameter / 2;
  const completeCircle = 360; // degrees
  // Create an array for the totalPercent so that it can be used to generate svg elements
  const completeCircleArray = new Array(completeCircle).fill(1);

  // The base circle
  // Hack: Background color changes depending on percentage parameter
  // This allows us to avoid rendering the arches later on (when percentage = 100 and >= 200)
  const baseCircle = completeCircleArray.map((_, index) => {
    const point = polarToCartesian(center, radius, index);
    return (
      <line
        stroke={
          percentage < 100 ? GREY : percentage >= 200 ? DARK_ACCENT : ACCENT
        }
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        key={index}
        x1={point.x}
        x2={point.x}
        y1={point.y}
        y2={point.y}
      />
    );
  });

  // Show percentage completed on the circle
  // 1- Convert percentage (number) to its position in degrees (number)
  const getDegreesFromPercentage = (percentage) => {
    const onePercentAsDegree = 360 / 100;
    const totalDegrees = percentage * onePercentAsDegree;
    return totalDegrees;
  };

  // 2- Get the Arch, represented as a percentage
  const getArc = (
    isFirstArc,
    startPointInDegrees,
    endPointInDegrees,
    strokeColor,
    percentage,
    strokeLinecap,
    strokeWidth
  ) => {
    // Get the circumference of the circle
    const circumference = Math.PI * diameter;
    // Find the number of stroke widths that make up the circumference
    const numStrokeWidthsInCircumference = (circumference / strokeWidth) * 50;
    // Get the equivalent degrees for one stroke width. Dividing by two as only half of the width is beyond the point
    const DEGREE_ADJUSTMENT_FOR_STROKE_WIDTH =
      360 / numStrokeWidthsInCircumference / 2;

    // Adjust both the start and end angles to account for the stroke width
    const firstArchShouldBeFilled = isFirstArc && percentage >= 100;
    const unadjustedStart =
      startPointInDegrees + 180 + DEGREE_ADJUSTMENT_FOR_STROKE_WIDTH;
    const unadjustedEnd = endPointInDegrees + 180;
    const adjustedStartAngle = firstArchShouldBeFilled
      ? unadjustedStart - startPointInDegrees
      : unadjustedStart;
    const adjustedEndAngle = firstArchShouldBeFilled
      ? unadjustedEnd
      : unadjustedEnd - DEGREE_ADJUSTMENT_FOR_STROKE_WIDTH;

    // Find the start point of the arc adjusting for the stroke width
    const startPoint = polarToCartesian(center, radius, adjustedStartAngle);
    // Find the end point of the arc adjusting for the stroke width
    const endPoint = polarToCartesian(center, radius, adjustedEndAngle);
    // Where an arc is larger than 180 degrees we need to tell the path to take the "longer" route.
    const largeArcFlag = adjustedEndAngle - adjustedStartAngle > 180 ? 1 : 0;

    // Find the point at which to start the extra line segment
    const startOfLineSegment = polarToCartesian(
      center,
      radius,
      adjustedStartAngle - DEGREE_ADJUSTMENT_FOR_STROKE_WIDTH
    );

    return [
      <path
        d={`M ${startOfLineSegment.x} ${startOfLineSegment.y} L ${startPoint.x} ${startPoint.y} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endPoint.x} ${endPoint.y}`}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap={strokeLinecap}
        fillOpacity="0"
        key={isFirstArc ? strokeLinecap : strokeLinecap}
      />,
    ];
  };

  // 4- Generate the arches
  // Note: We generate two arches for each, one with strokeLinecap="flat" and the second arch strokeLinecap="round"
  // The first two arches only render if the percentage is > 0 and < 100.
  // The second two arches only render if the percentage is > 100 and < 200.
  const firstArcFlat = getArc(
    true,
    0,
    getDegreesFromPercentage(percentage),
    ACCENT,
    percentage,
    "flat",
    strokeWidth
  );
  const firstArcRound = getArc(
    true,
    11, // "startPointInDegrees" should not be 0, but rather 11 so that we don't see the round edge
    getDegreesFromPercentage(percentage),
    ACCENT,
    percentage,
    "round",
    strokeWidth
  );
  const excessArcFlat = getArc(
    false,
    0,
    getDegreesFromPercentage(percentage - 100),
    DARK_ACCENT,
    percentage,
    "flat",
    strokeWidth
  );
  const excessArcRound = getArc(
    false,
    11, // "startPointInDegrees" should not be 0, but rather 11 so that we don't see the round edge
    getDegreesFromPercentage(percentage - 100),
    DARK_ACCENT,
    percentage,
    "round",
    strokeWidth
  );

  return (
    <svg width={chartWidth} height={chartWidth}>
      <g>
        {/* Note: Clock background color is grey if percent < 100; green up to 199, blue if >= 200 */}
        {baseCircle}

        {/* Note: The arches will not show up when the percentage is === 100% or >= 200% */}
        {/* Hack: The rounded cap only renders correctly on 4% and greater */}
        {percentage > 0 && percentage < 100 && firstArcFlat}
        {percentage > 3 && percentage < 100 && firstArcRound}

        {percentage > 100 && percentage < 200 && excessArcFlat}
        {percentage > 103 && percentage < 200 && excessArcRound}
      </g>
      {!hidePerecentageText && (
        <text
          textAnchor="middle"
          fontSize={fontSize}
          fontWeight={400}
          color={theme.neutral5al5}
          alignmentBaseline="central"
          x={center}
          y={center}
        >
          {isModelReady ? `${percentage.toFixed(0)}%` : "?"}
        </text>
      )}
    </svg>
  );
};

export default Donut;
