import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Components
import GuestPage from "../../Components/GuestPage";
import Page from "../../Components/Page";
import SecondaryButton from "../../Components/SecondaryButton";
import { NavigateBeforeIcon } from "../../Styles/Icons";
import styles from "./styles.module.scss";

const PrivacyPolicy = () => (
  <div className={styles.policy}>
    <h1>Intelligent Lilli Limited - Privacy Notice</h1>
    <p>
      We keep our privacy notice under regular review. This version was last
      updated on 11/03/2024.
    </p>
    <h2>Contents</h2>
    <ol className={styles.contentsList}>
      <li>Important Information About Intelligent Lilli Limited</li>
      <li>The data we collect about you</li>
      <li>How is your Personal Data Collected</li>
      <li>How we use your personal data</li>
      <ol className={styles.contentsSubList}>
        <li>Marketing</li>
        <li>Marketing and Opt-Out</li>
        <li>Cookies</li>
        <li>Change of purpose</li>
      </ol>
      <li>Service User Data</li>
      <li>Disclosures of your personal data</li>
      <li>International transfers</li>
      <li>Data Security</li>
      <li>Data Retention</li>
      <ol className={styles.contentsSubList}>
        <li>How long will you use my personal data?</li>
      </ol>
      <li>Automated Decision Making or Profiling</li>
      <li>Your legal rights</li>
      <ol className={styles.contentsSubList}>
        <li>What we may need from you</li>
        <li>Time limit to respond</li>
      </ol>
      <li>Links to other Websites</li>
      <li>How to make a Complaint</li>
      <li>Changes to this Privacy Notice</li>
    </ol>

    <ol className={styles.mainList}>
      <li>Important Information About Intelligent Lilli Limited</li>
      <p>
        Your privacy is important to us, and so is being transparent about how
        we collect, use and share information about you. Therefore, this privacy
        notice is intended to help you understand and will explain how your
        personal information is collected, used and shared by Intelligent Lilli
        Limited.
      </p>
      <p>
        Intelligent Lilli Limited (referred to in this notice as <b>“we”</b>,
        <b>“us”</b> or <b>“our”</b>) is registered in England and Wales under
        company number 08832088. Our registered office is at 17k Solent House,
        Lansbury Estate, Guildford Road, Knaphill, Woking, England, GU21 2EP.
      </p>
      <p>
        We are registered with the Information Commissioner’s Office (ICO) and
        our registration number is ZA154221.
      </p>
      <p>We process your personal data in one of two designations: </p>
      <ol className={styles.subList}>
        <li>
          in instances where we decide <i>how</i> and <i>why</i> your personal
          data is processed, we are a <b>data controller</b> over such data.
          This includes certain personal data we collect about you through your
          use of{" "}
          <a
            href="https://www.intelligentlilli.com/"
            target="_blank"
            rel="noreferrer"
          >
            intelligentlilli.com
          </a>{" "}
          (<b>"Website"</b>), via{" "}
          <a
            href="https://app.intelligentlilli.com/"
            target="_blank"
            rel="noreferrer"
          >
            app.intelligentlilli.com
          </a>{" "}
          (<b>"Web App"</b>) and the Intelligent Lilli mobile application (
          <b>"Mobile App"</b>).{" "}
          <u>
            <b>
              The relevant details and your rights in relation to such personal
              data are outlined in all sections of this privacy notice except
              section 5.
            </b>
          </u>{" "}
        </li>
        <li>
          where we process your personal data under the instructions of our
          customer (i.e. your care provider) in connection with the services and
          software platform we provide to that customer (including through use
          of sensors installed in your home and via the Web App or the Mobile
          App), we are a <b>data processor</b> in relation to such personal
          data. The relevant details of such personal data are outlined in
          section 5 of this privacy notice and are provided for information
          only. The primary responsibility to comply with the regulatory
          obligations (including the exercise of your legal rights) in relation
          to such personal data lies with the data controller, which is our
          customer and your care provider, and you should contact them for more
          information and in order to exercise your rights in relation to this
          processing.
        </li>
      </ol>
      <p>
        Please read this privacy notice carefully. Our products and services are
        not intended for children. We do not knowingly collect data relating to
        children nor do we sell our products to children.
      </p>
      <p>
        If you have any questions about this privacy notice, including any
        requests to exercise your legal rights in relation to personal data for
        which we are the data controller (please see Section 9 below) or contact
        our Data Protection Officer. Our Data Protection Officer is 8fold
        Governance Ltd (company registration number 12085647) and can be
        contacted at{" "}
        <a
          href="mailto: dpo@intelligentlilli.com"
          target="_blank"
          rel="noreferrer"
        >
          dpo@intelligentlilli.com
        </a>
        .
      </p>
      <p>
        The personal data we hold about you must be accurate and current. Please
        keep us informed if your personal data changes during your relationship
        with us.
      </p>
      <li>The data we collect about you</li>
      <p>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity has been removed (anonymous data). We may
        collect, use, store and transfer different kinds of personal data about
        you which we have grouped as follows:
      </p>
      <ul className={styles.mainUL}>
        <li>
          <b>Identity Data</b> includes first name, last name, username, title,
          job title, employer name, date of birth.
        </li>
        <li>
          <b>Contact Data</b> includes billing address, delivery address, email
          address and telephone numbers.
        </li>
        <li>
          <b>Technical Data</b> includes internet protocol (IP) address, your
          login data, browser type and version, time zone setting and location,
          browser plug-in types and versions, operating system and platform, and
          other technology on the devices you use to access our services.
        </li>
        <li>
          <b>Usage Data</b> includes information about how you use our service.
        </li>
        <li>
          <b>Marketing and Communications Data</b> includes your preferences in
          receiving marketing from us and our third parties and your
          communication preferences.
        </li>
      </ul>
      <h3>If you fail to provide personal data</h3>
      <p>
        Where we need to collect personal data by law, or under the terms of a
        contract we have with you, and you fail to provide that data when
        requested, we may not be able to perform the contract we have or are
        trying to enter into with you (for example, to provide you with our
        products). In this case, we may have to cancel your order.
      </p>
      <li>How is your Personal Data Collected</li>
      <h3>
        We use different methods to collect data from and about you including
        through:
      </h3>
      <ul className={styles.mainUL}>
        <li>
          <b>Direct interactions.</b> You may give us your Identity, Contact and
          Financial Data by filling in forms or by corresponding with us by
          post, phone, email or otherwise. This includes personal data you
          provide when you:
        </li>
        <ul className={styles.subUL}>
          <li>
            Create an account on the Web App or the Mobile App or provide
            information via our Website;
          </li>
          <li>Subscribe to our service or publications;</li>
          <li>Request marketing to be sent to you;</li>
          <li>Enter a competition, promotion or survey; or</li>
          <li>Give us feedback or contact us; or</li>
          <li>Request customer support.</li>
        </ul>
        <li>
          <b>Automated technologies or interactions.</b> As you interact with
          our service, we will automatically collect Technical Data about your
          equipment, browsing actions and patterns. We collect this personal
          data by using cookies and other similar technologies. We may also
          receive Technical Data about you if you visit other websites employing
          our cookies.
        </li>
        <li>
          <b>Third parties or publicly available sources.</b> We will receive
          personal data about you from various third parties as set out below:
        </li>
        <ul className={styles.subUL}>
          <li>Technical Data from analytics providers such as Google;</li>
          <li>
            Identity, Contact, Financial and Transaction Data from providers of
            technical, payment and delivery and related services;
          </li>
          <li>Identity and Contact Data from data brokers or aggregators;</li>
          <li>Identity and Contact Data from publicly available sources.</li>
        </ul>
      </ul>
      <li>How we use your personal data</li>
      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following circumstances:
      </p>
      <ul className={styles.mainUL}>
        <li>
          Where we need to perform the contract we are about to enter into or
          have entered into with you;
        </li>
        <li>
          Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests;
        </li>
        <li>Where we need to comply with a legal obligation.</li>
      </ul>
      <p>
        You have the right to withdraw consent to marketing at any time by
        contacting us.
      </p>
      <h3>Purposes for which we will use your personal data</h3>
      <p>
        We have set out below, in a table format, a description of all the ways
        we plan to use your personal data, and which of the legal bases we rely
        on to do so. We have also identified what our legitimate interests are
        where appropriate. Please contact us if you need details about the
        specific legal ground we are relying on to process your personal data
        where more than one ground has been set out in the table below.
      </p>
      <table>
        <thead>
          <tr>
            <th>Purpose/Activity</th>
            <th>Type of data</th>
            <th>
              Lawful basis for processing including basis of legitimate interest
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To register you as a new customer</td>
            <td>
              <p>(a) Identity</p>
              <p> (b) Contact </p>
            </td>
            <td>Performance of a contract with you</td>
          </tr>
          <tr>
            <td>
              To manage our relationship with you which will include:{" "}
              <p>
                (a) Notifying you about changes to our terms or privacy notice
              </p>
              <p>(b) Asking you to leave a review</p>
            </td>
            <td>
              <p> (a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Profile</p>
              <p>(d) Marketing and Communications</p>
            </td>
            <td>
              <p>(a) Performance of a contract with you</p>
              <p>(b) Necessary to comply with a legal obligation </p>
              <p>
                (c) Necessary for our legitimate interests (to keep our records
                updated and to study how customers use our products/services)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To enable you to partake in a prize draw, competition or complete
              a survey
            </td>
            <td>
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Profile </p>
              <p>(d) Usage </p>
              <p>(e) Marketing and Communications</p>
            </td>
            <td>
              <p>(a) Performance of a contract with you</p>
              <p>
                (b) Necessary for our legitimate interests (to study how
                customers use our products/services, to develop them and grow
                our business)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              To administer and protect our business, the Website, Web App and
              Mobile App (including troubleshooting, data analysis, testing,
              system maintenance, support, reporting and hosting of data)
            </td>
            <td>
              <p>(a) Identity </p>
              <p>(b) Contact </p>
              <p>(c) Technical</p>
            </td>
            <td>
              <p>
                (a) Necessary for our legitimate interests (for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise)
              </p>
              <p>(b) Necessary to comply with a legal obligation</p>
            </td>
          </tr>
          <tr>
            <td>
              To deliver relevant website content and advertisements to you and
              measure or understand the effectiveness of the advertising we
              serve to you
            </td>
            <td>
              <p>(a) Identity</p>
              <p>(b) Contact </p>
              <p>(c) Profile </p>
              <p>(d) Usage </p>
              <p>(e) Marketing and Communications</p>
              <p>(f) Technical</p>
            </td>
            <td>
              Necessary for our legitimate interests (to study how customers use
              our products/services, to develop them, to grow our business and
              to inform our marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To use data analytics to improve the Website, Web App and Mobile
              App, products/services, marketing, customer relationships and
              experiences
            </td>
            <td>
              <p>(a) Technical</p>
              <p>(b) Usage</p>
            </td>
            <td>
              Necessary for our legitimate interests (to define types of
              customers for our products and services, to keep the Website, Web
              App and Mobile App updated and relevant, to develop our business
              and to inform our marketing strategy)
            </td>
          </tr>
          <tr>
            <td>
              To make suggestions and recommendations to you about goods that
              may be of interest to you
            </td>
            <td>
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Technical</p>
              <p>(d) Usage</p>
              <p>(e) Profile</p>
              <p>(f) Marketing and Communications</p>
            </td>
            <td>
              Necessary for our legitimate interests (to develop our
              products/services and grow our business)
            </td>
          </tr>
          <tr>
            <td>To provide customer support</td>
            <td>
              <p>(a) Identity</p>
              <p>(b) Contact</p>
              <p>(c) Technical</p>
              <p>(d) Usage</p>
            </td>
            <td>
              Necessary for our legitimate interests (to provide customer
              support for the use of our products/services)
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Marketing</h3>
      <p>
        We strive to provide you with choices regarding certain personal data
        uses, particularly around marketing and advertising. We may use your
        Identity, Contact, Technical, Usage and Profile Data to form a view on
        what we think you may want or need, or what may be of interest to you.
        This is how we decide which products, services and offers may be
        relevant for you (we call this marketing). You will receive marketing
        communications from us if you have requested information from us or
        purchased goods or services from us and you have not opted out of
        receiving that marketing.
      </p>
      <h3>Marketing and Opt-Out</h3>
      <p>
        We will get your express opt-in consent before we share your personal
        data with any third party for marketing purposes. You can ask us or
        third parties to stop sending you marketing messages at any time by
        contacting us (see our Contact Details at Section 1 above). Where you
        opt-out of receiving these marketing messages, this will not apply to
        personal data provided to us as a result of a product purchase or other
        transactions.
      </p>
      <h3>Cookies</h3>
      <p>
        You can set your browser to refuse all or some browser cookies or to
        alert you when websites set or access cookies. Please note that some
        parts of the Website may become inaccessible or not function properly if
        you disable or refuse cookies. For more information about the cookies we
        use, please see our{" "}
        <a
          href="https://www.intelligentlilli.com/cookie-policy/"
          target="_blank"
          rel="noreferrer"
        >
          cookie policy
        </a>
        .
      </p>
      <h3>Change of purpose</h3>
      <p>
        We will only use your personal data for the purposes for which we
        collected it unless we reasonably consider that we need to use it for
        another reason and that reason is compatible with the original purpose.
        If you wish to get an explanation as to how the processing for the new
        purpose is compatible with the original purpose, please contact us. If
        we need to use your personal data for an unrelated purpose, we will
        notify you and we will explain the legal basis which allows us to do so.
        Please note that we may process your personal data without your
        knowledge or consent, in compliance with the above rules, where this is
        required or permitted by law.
      </p>
      <li>Service User Data</li>
      <p>
        Where you are the end user of care services provided by one of our
        customers through use of our service (made up of sensors / smart devices
        we provide, the Web App and / or the Mobile App) (<b>"Service User"</b>
        ), we may process personal data about you as a data processor on behalf
        of our customer, your care provider. This data may include Identity
        Data. In addition, our smart devices collect data from sensors installed
        in your home such as temperature, motion, humidity etc. which we make
        available to the Service User, carers employed or engaged by our
        customers (the Service User's care provider) and the Service User's
        authorised family members (together, the <b>"Authorised User Group"</b>
        ). Through analysis of the streams of sensor data we may be able to
        determine when there are significant deviations from expected values,
        and notify the Authorised User Group. In some instances, the analysis of
        the sensor data may enable us and the Authorised User Group to infer
        personal data regarding your health.
      </p>
      <p>
        You may view and amend your Authorised User Group on the "Device Info"
        page of the Web App and Mobile App. The processing of all information on
        behalf of our customers in relation to Service Users takes places
        entirely in the UK, by our UK staff. Only your care provider, the
        Service User and the Authorised User Group have access to this
        information.
      </p>
      <p>
        To exercise your data protection rights in relation to such personal
        data and for more information on the particulars of processing, please
        contact your care provider (the data controller) directly.
      </p>
      <li>Disclosures of your personal data</li>
      <p>
        We may share your personal data with the parties set out below for the
        purposes set out in the table at section 4 above – Purposes for which we
        will use your personal data:
      </p>
      <ul className={styles.mainUL}>
        <li>
          Service providers based in the United Kingdom provide IT and system
          administration services.
        </li>
        <li>
          Professional advisers including lawyers, bankers, auditors and
          insurers based in the United Kingdom who provide professional
          services.
        </li>
        <li>
          HM Revenue & Customs, regulators and other authorities in the United
          Kingdom.
        </li>
        <li>
          Third parties with whom we work in the course of providing the
          Services and carrying out ancillary activities, such as product/order
          packers and despatchers, mailing houses, banks and card and other
          payment service providers, market researchers, fraud prevention
          agencies and services that allow us to debug problems on our site and
          services.
        </li>
        <li>
          Third parties to whom we may choose to sell, transfer or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy notice.
        </li>
      </ul>
      <p>
        We require all third parties to respect the security of your personal
        data and to treat it in accordance with the law.
      </p>
      <li>International transfers</li>
      <p>We do not transfer your personal data outside the United Kingdom.</p>
      <li>Data Security</li>
      <p>
        We have put in place appropriate organisational and technical security
        measures to prevent your personal data from being accidentally lost,
        used or accessed in an unauthorised way, altered or disclosed. In
        addition, we limit access to your personal data to those employees,
        agents, contractors and other third parties who have a business need to
        know. They will only process your personal data on our instructions and
        they are subject to a duty of confidentiality. We have put in place
        procedures to deal with any suspected personal data breach and will
        notify you and any applicable regulator of a breach where we are legally
        required to do so.
      </p>
      <li>Data Retention</li>
      <h3>How long will you use my personal data?</h3>
      <p>
        We will only retain your personal data for as long as reasonably
        necessary to fulfil the purposes we collected it for, including to
        satisfy any legal, regulatory, tax, accounting or reporting
        requirements. We may retain your personal data for a longer period in
        the event of a complaint or if we reasonably believe there is a prospect
        of litigation in respect to our relationship with you.
      </p>
      <p>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal, regulatory, tax, accounting or other requirements.
      </p>
      <li>Automated Decision Making or Profiling</li>
      <p>
        We do not undertake any automated decision making or profiling in
        relation to your personal data.
      </p>
      <li>Your legal rights</li>
      <p>
        Under certain circumstances, you have rights under data protection laws
        in relation to your personal data. You have the right to:
      </p>
      <ul className={styles.mainUL}>
        <li>
          Request access to your personal data (commonly known as a “data
          subject access request”). This enables you to receive a copy of the
          personal data we hold about you and to check that we are lawfully
          processing it.
        </li>
        <li>
          Request correction of the personal data that we hold about you. This
          enables you to have any incomplete or inaccurate data we hold about
          you corrected, though we may need to verify the accuracy of the new
          data you provide to us.
        </li>
        <li>
          Request erasure of your personal data. This enables you to ask us to
          delete or remove personal data where there is no good reason for us to
          continue to process it. You also have the right to ask us to delete or
          remove your personal data where you have successfully exercised your
          right to object to processing (see below), where we may have processed
          your information unlawfully or where we are required to erase your
          personal data to comply with local law. Note, however, that we may not
          always be able to comply with your request of erasure for specific
          legal reasons which will be notified to you, if applicable, at the
          time of your request.
        </li>
        <li>
          Object to processing of your personal data where we are relying on a
          legitimate interest (or those of a third party) and there is something
          about your particular situation which makes you want to object to
          processing on this ground as you feel it impacts on your fundamental
          rights and freedoms. You also have the right to object where we are
          processing your personal data for direct marketing purposes. In some
          cases, we may demonstrate that we have compelling legitimate grounds
          to process your information which override your rights and freedoms.
        </li>
        <li>
          Request restriction of processing of your personal data. This enables
          you to ask us to suspend the processing of your personal data: (1) If
          you want us to establish the data’s accuracy; (2) Where our use of the
          data is unlawful but you do not want us to erase it; (3) Where you
          need us to hold the data even if we no longer require it as you need
          it to establish, exercise or defend legal claims; or (4) You have
          objected to our use of your data but we need to verify whether we have
          overriding legitimate grounds to use it.
        </li>
        <li>
          Request the transfer of your personal data to you or a third party. We
          will provide to you, or a third party you have chosen, your personal
          data in a structured, commonly used, machine-readable format. Note
          that this right only applies to automated information which you
          initially provided consent for us to use or where we used the
          information to perform a contract with you.
        </li>
        <li>
          Withdraw consent at any time where we are relying on consent to
          process your personal data. However, this will not affect the
          lawfulness of any processing carried out before you withdraw your
          consent. If you withdraw your consent, we may not be able to provide
          certain products or services to you. We will advise you if this is the
          case at the time you withdraw your consent.
        </li>
      </ul>
      <h3>What we may need from you</h3>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        about your request to speed up our response.
      </p>
      <h3>Time limit to respond</h3>
      <p>
        We try to respond to all legitimate requests within one month.
        Occasionally it could take us longer than a month if your request is
        particularly complex or you have made several requests. In this case, we
        will notify you and keep you updated.
      </p>
      <p>
        In order to exercise any of these rights you should contact our Data
        Protection Officer by emailing dpo@intelligentlilli.com.
      </p>
      <li>Links to other Websites</li>
      <p>
        The Website, the Mobile App and the Web App may include links to
        third-party websites, plug-ins and applications. Clicking on those links
        or enabling those connections may allow third parties to collect or
        share data about you. This privacy notice applies only to the Website,
        the Web App and the Mobile App so when you visit other websites please
        read their privacy notices, as we cannot accept any responsibility for
        breaches or issues you may have in relation to data privacy on other
        websites or applications, including your care provider's.{" "}
      </p>
      <li>How to make a Complaint</li>
      <p>
        We encourage you to contact us at dpo@intelligentlilli.com if you think
        that any collection or use of your personal data by us is unfair,
        misleading or inappropriate. If you make a complaint to us and think we
        have not dealt with it to your satisfaction, you have the right to make
        a complaint to the supervisory authority{" "}
        <a
          href="https://ico.org.uk/make-a-complaint/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <li>Changes to this Privacy Notice</li>
      <p>
        We keep our privacy notice under regular review. If we change our
        privacy policy we will post the changes on this page, so that you may be
        aware of the information we collect and how we use it at all times. This
        version was last updated on 11/03/2024.
      </p>
    </ol>
  </div>
);

function Privacy() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  if (user) {
    return (
      <Page>
        <PrivacyPolicy />
      </Page>
    );
  } else {
    return (
      <GuestPage
        subtitle={
          <SecondaryButton
            startIcon={<NavigateBeforeIcon />}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </SecondaryButton>
        }
        removeSandBackground={true}
      >
        <PrivacyPolicy />
      </GuestPage>
    );
  }
}

export default Privacy;
