import React from "react";
import styles from "./styles.module.scss";
import InstallDescription from "../InstallDescription";
import InstallNote from "../InstallNote";

const DesktopInstructions = ({ instructions }) => {
  return (
    <div className={styles.desktop_instructions}>
      <ol className={styles.instructions_steps}>
        {instructions.map((item, index) => {
          if (item.text) {
            return <li key={index}>{item.text}</li>;
          }
          if (item.image) {
            return (
              <div style={{ marginBottom: "16px" }}>
                <img
                  key={index}
                  src={item.image.image}
                  alt={item.image.alt}
                  style={{
                    maxHeight: `${item?.image.height}px`,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                />
              </div>
            );
          }
          if (item.note) {
            return <InstallNote note={item.note} key={index} />;
          }
          if (item.description) {
            return (
              <InstallDescription description={item.description} key={index} />
            );
          }
          return null;
        })}
      </ol>
    </div>
  );
};

export default DesktopInstructions;
