import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

const Backdrop = ({ show, className, onClick }) => {
  return (
    show && (
      <div
        className={classNames(styles.backdrop, className)}
        onClick={onClick}
        data-test-id="backdrop"
      />
    )
  );
};

export default Backdrop;
