export const processClipboardData = ({
  reportData,
  userDetailsToRender,
  reportDetailsToRender,
  firstName,
}) => {
  // join the pairs of elements in the user details array to present the data for copying
  const userDetailsToCopy = userDetailsToRender.reduce((acc, curr, i) => {
    if (i % 2 === 0) {
      acc.push(`• ${curr} ${userDetailsToRender[i + 1]}`);
    }
    return acc;
  }, []);
  // Do the same for the report details
  const reportDetailsToCopy = reportDetailsToRender.reduce((acc, curr, i) => {
    if (i % 2 === 0) {
      acc.push(`• ${curr} ${reportDetailsToRender[i + 1]}`);
    }
    return acc;
  }, []);

  // Process the report sections
  const reportSectionsToCopy = reportData.sections
    .map((section) => {
      return [
        "\n ",
        section.section_title,
        "\n ",
        ...section.questions.map((question) => {
          const questions = [];
          questions.push(question.question_text);
          question.sentences.data &&
            questions.push(`• ${question.sentences.data}`);
          question.sentences.lowerbound_risk &&
            questions.push(`• ${question.sentences.lowerbound_risk}`);
          question.sentences.upperbound_risk &&
            questions.push(`• ${question.sentences.upperbound_risk}`);
          question.sentences.trend &&
            questions.push(`• ${question.sentences.trend}`);
          questions.push("\n ");
          return questions;
        }),
      ];
    })
    .flat(2)
    .filter((item) => item !== undefined)
    .map((item) => item.replace("{name}", firstName))
    .map((item) => item.replace("<placeholder_name>", firstName));

  // Join all the sections together
  const copyReportText = [
    "Lilli user details",
    ...userDetailsToCopy,
    "\n ",
    "Report details",
    ...reportDetailsToCopy,
    ...reportSectionsToCopy,
  ];

  return `${copyReportText.join("\n ")}`;
};
