import styles from "./styles.module.scss";

const RiskDates = ({ dates, presentRiskDates, riskValue }) => {
  const noData = riskValue !== 2 && riskValue !== 1 && riskValue !== 0;
  return (
    <div className={styles.risk_container}>
      <div className={styles.risk_category_title}>
        {riskValue === 2
          ? "At risk"
          : riskValue === 1
            ? "Unexpected"
            : riskValue === 0
              ? "Expected"
              : "No Data"}
      </div>
      <div className={styles.risk_category_caption}>
        {noData
          ? "There was no data available on the following days"
          : "Click to view the details of any day"}
      </div>
      <div className={styles.risk_dates}>
        {dates?.length > 0 && (
          <div>{presentRiskDates(dates, riskValue, "do LLL")}</div>
        )}
      </div>
    </div>
  );
};

const BehaviourDaysSortedByRisk = ({
  presentRiskDates,
  daysAtRisk,
  daysUnexpected,
  daysNoData,
}) => (
  <section className={styles.behaviour_days_risk_sorting_container}>
    {daysAtRisk?.length > 0 && (
      <RiskDates
        dates={daysAtRisk}
        riskValue={2}
        presentRiskDates={presentRiskDates}
      />
    )}

    {daysUnexpected?.length > 0 && (
      <RiskDates
        dates={daysUnexpected}
        riskValue={1}
        presentRiskDates={presentRiskDates}
      />
    )}

    {daysNoData?.length > 0 && (
      <RiskDates
        dates={daysNoData}
        riskValue={null}
        presentRiskDates={presentRiskDates}
      />
    )}
  </section>
);
export default BehaviourDaysSortedByRisk;
