import packageInfo from "../../package.json";

// Get the correct server url depending on environment
function getServerURL(environment) {
  switch (environment) {
    case "production":
      return "https://api-cached.intelligentlilli.com";
    // return process.env.REACT_APP_API_URL;
    // To test a cloudflare worker route all requests to our dev environment and deploy to app-test
    // return "https://worker-dev.intelligentlilli.com";
    // To turn off caching simply replace the above url with the below one. This will make all HTTP requests directly to the API
    // return "https://api.intelligentlilli.com";
    case "test":
      return "http://localtest.me:8044";
    case "development":
      return "https://api-cached.intelligentlilli.com";
    // return process.env.REACT_APP_API_URL;
    // return "https://api.dev.intelligentlilli.com";
    // return "https://worker-dev.intelligentlilli.com";
    default:
      console.error("Environment not an expected value!" + environment);
      /// assume dev as a fallback
      return "http://localtest.me:8044";
  }
}

export const SERVER = getServerURL(process.env.NODE_ENV);

export const CLIENT_VERSION = packageInfo.version;

export const mobileBreakpoint = 600;

export const reportsBreakpoint = 800;

export const tabletBreakpoint = 900;

export const widescreenBreakpoint = 1200;
