import React from "react";
import styles from "./styles.module.scss";
// Components
import { Avatar } from "../Avatar";
import { AvatarStaffToolTip, TextStaffToolTip } from "../StaffToolTip";
import {
  getInitials,
  getInitialsFromNames,
  capitalize,
  RiskLevel,
  RiskLevelText,
} from "@intelligentlilli/lilli-utils";
import { useViewport } from "../../Components/ViewportProvider";

const ServiceUserListItem = ({
  serviceUser,
  onClick,
  hasSearchTerm,
  justAnInstaller,
}) => {
  const staff = serviceUser.viewers || [];
  const careID =
    serviceUser?.serviceUserProperties?.mosaicID ||
    serviceUser?.serviceUserProperties?.careId ||
    serviceUser?.hubProperties?.mosaicId ||
    serviceUser?.hubProperties?.careID ||
    "Not set";
  const riskStatus = serviceUser?.riskStatus;
  const riskStatusText = RiskLevelText[RiskLevel[riskStatus]];
  const lessThan6Viewers = serviceUser?.viewers?.length < 6;
  const { width } = useViewport();
  const isTablet = width < 769;

  const numOfMatchedStaff = staff.reduce(
    (tot, staffMember) => (staffMember.highlight ? tot + 1 : tot),
    0
  );
  const numOfViewers = staff.length;
  const staffString = numOfMatchedStaff
    ? `${numOfMatchedStaff} / ${numOfViewers}`
    : numOfViewers;

  return (
    <>
      {isTablet && (
        <tr className={styles.tr}>
          <td>
            <div
              className={styles.mobile_avatar_and_name_and_care_id}
              onClick={onClick}
            >
              <span data-private className={styles.mobile_avatar}>
                <Avatar
                  large
                  initials={getInitials(serviceUser.userstring)}
                  altText={`Avatar for ${serviceUser.userstring}`}
                />
              </span>
              <div className={styles.mobile_userstring_and_care_id}>
                <div data-private className={styles.mobile_userstring}>
                  {serviceUser.userstring}
                </div>
                <div data-private className={styles.mobile_care_id}>
                  {careID || "Care ID not set"}
                </div>
              </div>
            </div>
          </td>
          {!justAnInstaller && (
            <td className={styles.td_mobile_risk_status}>
              <p
                className={`
                ${styles.mobile_risk_status_text}
                ${
                  riskStatus === 0
                    ? styles.expected
                    : riskStatus === 2
                      ? styles.at_risk
                      : riskStatus === 1
                        ? styles.unexpected
                        : styles.no_risk_status
                }
              `}
              >
                {riskStatusText}
              </p>
            </td>
          )}
          {justAnInstaller && (
            <td className={styles.td_mobile_installation_status}>
              {serviceUser?.installationStatus &&
                capitalize(serviceUser?.installationStatus)}
            </td>
          )}
        </tr>
      )}

      {!isTablet && (
        <tr className={styles.tr}>
          <td>
            <div
              data-private
              className={styles.td_avatar_and_name}
              onClick={onClick}
            >
              <Avatar
                initials={getInitials(serviceUser.userstring)}
                altText={`Avatar for ${serviceUser.userstring}`}
              />
              <span data-private className={styles.td_service_user_name}>
                {serviceUser.userstring}
              </span>
            </div>
          </td>
          <td data-private className={styles.td_care_id}>
            {careID || "-"}
          </td>
          {!justAnInstaller && (
            <td className={styles.td_staff}>
              {!lessThan6Viewers ? (
                <span className={styles.more_than_six_viewers}>
                  {`${staffString} Staff members`}
                  <span className={styles.more_than_six_viewers_tooltip}>
                    {serviceUser.viewers && (
                      <TextStaffToolTip
                        viewers={serviceUser.viewers}
                        hasStaffMatch={numOfMatchedStaff}
                      />
                    )}
                  </span>
                </span>
              ) : (
                serviceUser?.viewers?.map((viewer, index) => (
                  <span key={index}>
                    <Avatar
                      defaultCursor
                      small
                      overlap
                      lowerOpacity={
                        (numOfMatchedStaff || hasSearchTerm) &&
                        !viewer.highlight
                      } // if it is not a match, avatar bkgd has opacity set to 0.2
                      green={index % 2 ? true : false}
                      initials={`${
                        viewer.forenames && viewer.surname
                          ? getInitialsFromNames(
                              viewer.forenames,
                              viewer.surname
                            )
                          : getInitials(viewer.username).toUpperCase()
                      }`}
                      altText={`Avatar for ${
                        viewer.forenames && viewer.surname
                          ? `${viewer.forenames && viewer.surname}`
                          : `${viewer.username}`
                      }
                        `}
                      avatarToolTip={
                        <AvatarStaffToolTip
                          staff={viewer}
                          avatarGreen={index % 2 ? true : false}
                        />
                      }
                    />
                  </span>
                ))
              )}
            </td>
          )}
          <td className={styles.td_installation}>
            {serviceUser?.installationStatus &&
              capitalize(serviceUser?.installationStatus)}
          </td>
          {!justAnInstaller && (
            <td>
              <p
                className={`
                ${styles.desktop_risk_status}
                ${
                  riskStatus === 0
                    ? styles.expected
                    : riskStatus === 2
                      ? styles.at_risk
                      : riskStatus === 1
                        ? styles.unexpected
                        : styles.no_risk_status
                }
              `}
              >
                {riskStatusText && capitalize(riskStatusText)}
              </p>
            </td>
          )}
        </tr>
      )}
    </>
  );
};

export default ServiceUserListItem;
